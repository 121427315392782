import { useEffect, useRef } from "react";

const useClickOutside = (onClick, stopPropagation) => {
  const el = useRef(null);

  useEffect(() => {
    const listener = (event) => {
      if (el.current && !el.current.contains(event.target)) {
        if (stopPropagation) {
          event.stopPropagation();
          event.preventDefault();
        }
        onClick(event);
      }
    };

    /* listens to the capture phase */
    document.addEventListener("click", listener, { capture: true });

    return () => {
      document.removeEventListener("click", listener, { capture: true });
    };
  }, [onClick, stopPropagation]);

  return el;
};

export default useClickOutside;
