import React, { useState, useEffect } from "react";

import { get } from "@/modules/utils";
import FilmRow from "@/components/FilmRow";
import { identityHeadersPromise } from "@/providers/IdentityProvider";

const Films = (props) => {
  const [films, setFilms] = useState([]);

  useEffect(() => {
    async function fetchFilms() {
      const headers = await identityHeadersPromise();

      window
        .fetch("/.netlify/functions/get-films", {
          method: "GET",
          credentials: "include",
          headers,
        })
        .then((res) => res.json())
        .then((data) => {
          if (get(data, "films.0")) setFilms(data.films);
        })
        .catch((err) => {
          console.error("fetch films error:", err);
        });
    }

    fetchFilms();
  }, []);

  return (
    <div>
      <div>
        <div className="container--xs"></div>
      </div>
      <div className="films__wrapper">
        <div className="films__header f jcb aic">
          <div className="ml05">
            <h5 className="caps akz-e">Films</h5>
          </div>
          <div className="mr05">
            <h5 className="caps akz-e col-1">US Release Date</h5>
          </div>
        </div>
        <div className="films__content x">
          {films.map((film) => (
            <FilmRow content={film} key={film._id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Films;
