import React from "react";
import cx from "classnames";

import { useCart } from "@/providers/CartProvider";
import Header from "./Header";
import MiniCart from "./MiniCart";

import { Checkout } from "./Checkout";
import { Footer } from "./Footer";

function Layout(props) {
  const { isOpen, isCheckoutOpen } = useCart();

  return (
    <>
      <div className="fix fill f jce">
        <Checkout />
        <MiniCart />
      </div>

      <div className="f jce">
        <div className="rel x main bcb f fdc">
          <Header className="x" />

          <div className="container--l mxa x p15 outer">
            <main>{props.children}</main>
          </div>

          <Footer className="x mta" />
        </div>

        <div
          className={cx("space rel", {
            "show-cart": isOpen,
            "show-checkout": isCheckoutOpen,
          })}
        />
      </div>
    </>
  );
}

export default Layout;
