import React from "react";
import { get } from "modules/utils";
import Login from "../auth/Login";
import { useIdentity } from "../providers/IdentityProvider";

function PrivateRoute({ as: Component, adminOnly, ...props }) {
  const { isLoggedIn, user } = useIdentity();
  const { uri, navigate } = props;

  let authorized = isLoggedIn;

  if (adminOnly) {
    const roles = get(user, "app_metadata.roles", null);
    authorized = roles && roles.includes("admin");
  }

  React.useEffect(() => {
    if (!isLoggedIn && uri !== "/") {
      navigate("/", { replace: true });
    }
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  return authorized ? <Component {...props} /> : <Login />;
}

export default PrivateRoute;
