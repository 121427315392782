import React from "react";
import { Router } from "@reach/router";

import "./index.css";

import IdentityProvider, { useIdentity } from "providers/IdentityProvider";
import CartProvider from "providers/CartProvider";
import OrdersProvider from "providers/OrdersProvider";
import SavedPageState from "providers/SavedPageState";

import PrivateRoute from "components/PrivateRoute";
import Layout from "components/Layout";

import Login from "auth/Login";
import Forgot from "auth/Forgot";

import Films from "pages/Films";
import Film from "pages/Film";
import Asset from "pages/Asset";
import Admin from "pages/Admin";
import Terms from "pages/Terms";
import OrderHistory from "pages/OrderHistory";
import Order from "pages/Order";

function AuthWatcher() {
  const { isLoggedIn, setUser } = useIdentity();

  React.useEffect(() => {
    if (!window.netlifyIdentity) return;

    window.netlifyIdentity.on("login", (user) => {
      setUser(user);
      window.netlifyIdentity.close();
    });
    window.netlifyIdentity.on("logout", () => setUser(null));
  }, [setUser]);

  React.useEffect(() => {
    if (!isLoggedIn) {
      if (window.netlifyIdentity.currentUser()) {
        document.location.replace("/"); // logout() from widget
      }
    }
  }, [isLoggedIn]);

  return null;
}

const Providers = (props) => (
  <IdentityProvider>
    <CartProvider>
      <OrdersProvider>
        <SavedPageState>{props.children}</SavedPageState>
      </OrdersProvider>
    </CartProvider>
  </IdentityProvider>
);

function App() {
  return (
    <div className="App">
      <Providers>
        <Layout>
          <Router>
            <PrivateRoute as={Films} default />
            <PrivateRoute as={Film} path="/films/:slug" />
            <PrivateRoute as={Asset} path="/films/:slug/:assetSlug" />
            <PrivateRoute as={Admin} adminOnly path="/admin" />
            <Login path="/login" />
            <Forgot path="/forgot" />
            <Terms path="/terms" />
            <Order path="/orders/:orderId" />
            <OrderHistory path="/orders" />
          </Router>
        </Layout>

        <AuthWatcher />
      </Providers>
    </div>
  );
}

export default App;
