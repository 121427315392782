import React from "react";

export const CheckoutButton = (props) => (
  <div className={props.className}>
    <button
      className="if jcs aic my05 hover block asset__add-to-cart caps akz black"
      id="checkout-button-sku_G9OyKbsxZ5RNDI"
      role="link"
      onClick={props.onClick}
    >
      Checkout
      <div className="asset__add-to-cart__arrow ml05">
        <svg
          fill="currentColor"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 55 20"
        >
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            points="43.3,0 55,10 43.3,20 42.4,19.1 52.2,10.7 0,10.7 0,9.3 52.2,9.3   42.4,0.9 43.3,0 "
          />
        </svg>
      </div>
    </button>
  </div>
);
