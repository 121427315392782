/**
 * Validation
 */
const websiteRegex =
  /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const nonStrictIntlZip = /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/;

const requiredMsg = "Required";
const required = (val) => !val && requiredMsg;
const tooShort = (val, min = 2) => val && val.length <= min && "Too short";

/* Returns error message, or false if no error */
export const getErrorMessage = (field, val) => {
  switch (field) {
    /* Required */
    case "name":
      return required(val) || tooShort(val);
    case "company":
      return required(val) || tooShort(val);
    case "country":
      return required(val);
    case "companyId":
      return required(val) || tooShort(val);
    /* Optional */
    case "province":
      return tooShort(val, 1);
    case "zip":
      return val && !nonStrictIntlZip.test(val) && "Invalid zip";
    case "website":
      return val && !websiteRegex.test(val) && "Invalid url";
    case "street2":
      return false;
    case "reference":
      return false;
    case "note":
      return false;
    default:
      return tooShort(val);
  }
};

/**
 * Form Error Summary - a summary of all the errors, for below the submit button
 */
const summaryText = (arr, rule) => {
  const a = [...arr];
  const last = a.pop();
  const fields = `${a.join(", ")}${
    a.length ? `${a.length === 1 ? "" : ","} and ` : ""
  }${last ?? ""}`;
  const msg = `${fields} ${arr.length === 1 ? "is" : "are"} ${rule}.`;
  return msg.charAt(0).toUpperCase() + msg.slice(1);
};
const getErrorSummary = (errors) => {
  const missing = Object.entries(errors)
    .map(([k, v]) => v && v === requiredMsg && k)
    .filter(Boolean);
  const invalid = Object.entries(errors)
    .map(([k, v]) => v && v !== requiredMsg && k)
    .filter(Boolean);

  if (!missing.length && !invalid.length) return null;

  return [
    ...(missing.length ? [summaryText(missing, "required")] : []),
    ...(invalid.length ? [summaryText(invalid, "invalid")] : []),
  ];
};

/**
 * Form Reducer helpers
 *                 * state *   * action *  */
const setValues = ({ values }, { payload }) => ({ ...values, ...payload });
const setErrors = ({ errors }, { payload }) => ({ ...errors, ...payload });
const unsetErrors = ({ errors }, { payload }) => ({
  ...errors,
  [payload]: false,
});
const validate = (state, { payload: field }) => {
  const msg = getErrorMessage(field, state.values[field]);
  const payload = msg ? { [field]: msg } : field;
  return msg ? setErrors(state, { payload }) : unsetErrors(state, { payload });
};

export const reset = ({ values = {}, errors = {} }) => ({ values, errors });

const processErrors = (state, newErrors) => ({
  ...state,
  errorSummary: getErrorSummary(newErrors),
  errors: newErrors,
});

/**
 * Form Reducer - field values and error statuses
 */
function formReducer(state, action) {
  switch (action.type) {
    case "init": {
      const newState = { ...state, ...action.payload };
      return processErrors(newState, {});
    }
    case "reset": {
      const blankState = { values: {}, errors: {} };
      const newState = { ...blankState, ...action.payload };
      return processErrors(newState, {});
    }
    case "setValue": {
      const field = Object.keys(action.payload)?.[0];
      const newState = { ...state, values: setValues(state, action) };
      return !state.errors[field] && state.errors[field] !== false
        ? newState
        : processErrors(newState, validate(newState, { payload: field }));
    }
    case "setError":
      return processErrors(state, setErrors(state, action));
    case "unsetError":
      return processErrors(state, unsetErrors(state, action));
    case "validate":
      return processErrors(state, validate(state, action));
    default:
      return state;
  }
}

export default formReducer;
