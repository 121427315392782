import React, { useState, useCallback, useEffect, useRef } from "react";

import { identityHeadersPromise } from "@/providers/IdentityProvider";
import { useCart } from "@/providers/CartProvider";
import { useAccordionContext } from "@/components/Accordion";

const debug = window.debugClientId || false;

const AuthorizationIDField = (props) => {
  const container = useRef();
  const [placeholder, setMessage] = useState("Enter code");
  const [isApplying, setIsApplying] = useState(false);
  const [value, setValue] = useState("");
  const { setCart, discount } = useCart();

  const accordion = useAccordionContext();
  const tabIndex = accordion?.isClosed ? -1 : 0;

  const [dots, setDots] = useState(".");
  useEffect(() => {
    const full = dots.length >= 5;
    const empty = dots.length === 0;
    const timer = setTimeout(
      () => setDots(full ? "" : `${dots} .`),
      empty ? 200 : 300,
    );
    return () => {
      clearTimeout(timer);
    };
  }, [dots]);

  useEffect(() => {
    setValue(discount?.code ?? "");
    setMessage("Enter code");
  }, [discount]);

  const handleSubmit = useCallback(
    async (ev, code) => {
      const focused = document.activeElement;
      if (focused && !container.current.contains(focused)) {
        // the user pressed enter, but they weren't in this nested form
        // kick 'em up to the REAL checkout form
        return;
      }

      ev.preventDefault();

      const headers = await identityHeadersPromise();

      setIsApplying(true);
      setDots(".");
      // give a few ms for fast connections
      setTimeout(() => setValue(""), 200);
      debug &&
        console.log("%ccoupon code ", "background:#ffd040; color:#222;", code);

      window
        .fetch("/.netlify/functions/apply-authorization-id", {
          method: "POST",
          credentials: "include",
          headers,
          body: JSON.stringify({ code }),
        })
        .then(async (res) => {
          setIsApplying(false);
          if (res.status === 200) {
            const data = await res.json();
            setValue(code);
            setCart(data);
            setMessage("");
          } else if (res.status === 409) {
            setMessage("Authorization already used");
          } else if (res.status === 400) {
            setMessage("Authorization not received");
          } else {
            const data = await res.json();

            if (data.msg) {
              setMessage(data.msg);
            }
            debug &&
              console.log(
                `%cstatus: ${res.status} data:`,
                "background:blue; color:white;",
                data,
              );
          }
        })
        .catch((err) => {
          console.error(err);
          setMessage("Invalid authorization");
        })
        .finally(() => {
          setIsApplying(false);
        });
    },
    [setCart],
  );

  return (
    <div className="auth-id pb05" ref={container}>
      <div className="if x">
        <input
          className="auth__input fa"
          type="text"
          name="coupon"
          value={value}
          tabIndex={tabIndex}
          onChange={({ target }) => setValue(target.value.toUpperCase())}
          disabled={isApplying}
          placeholder={isApplying ? `applying${dots}` : placeholder}
        />
        <button
          className="button akz caps"
          onClick={(ev) => handleSubmit(ev, value)}
          disabled={!value}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default AuthorizationIDField;
