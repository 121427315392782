import React, { useContext } from "react";
import { useNetlifyIdentity } from "react-netlify-identity";

import { get, isLocalStorageAvailable } from "modules/utils";

const IdentityContext = React.createContext();

const IdentityProvider = (props) => {
  const domain = `https://${process.env.REACT_APP_NETLIFY_DOMAIN_NAME}`;
  const enableRunRoutes = false; // enableRunRoutes OFF, so invites and reset password will go through the widget instead

  const identity = useNetlifyIdentity(domain, () => {}, enableRunRoutes);
  window.identity = identity;

  return (
    <IdentityContext.Provider value={identity}>
      {props.children}
    </IdentityContext.Provider>
  );
};

export const identityHeadersPromise = () => {
  const headers = { "Content-Type": "application/json" };

  let user = get(window.identity, "user");
  user = user || window.netlifyIdentity.currentUser();

  if (!user) {
    return Promise.resolve(headers);
  }

  return user
    .jwt()
    .then((token) => ({
      ...headers,
      Authorization: `Bearer ${token}`,
    }))
    .catch((e) => {
      console.error(e);

      let timeout = 4000;

      if (isLocalStorageAvailable()) {
        try {
          const lastRetry = localStorage.getItem("lastLoginRetry");
          const now = new Date().getTime();
          localStorage.setItem("lastLoginRetry", now);

          if (now - +lastRetry > timeout) {
            timeout = 500;
          }
        } catch (err) {}
      }
      console.warn(`reloading in ${timeout / 1000}s`);
      setTimeout(() => window.location.reload(), timeout);
    });
};

export const useIdentity = () => useContext(IdentityContext);

export default IdentityProvider;
