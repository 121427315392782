import React from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
// import { isDev } from "../modules/utils";

export const EmblaCarousel = (props) => {
  const [emblaRef /* emblaApi */] = useEmblaCarousel({ loop: true }, [
    Autoplay(),
  ]);

  // useEffect(() => {
  //   if (emblaApi && isDev()) {
  //     console.log(emblaApi.slideNodes()); // Access API
  //   }
  // }, [emblaApi]);
  return (
    <div className="embla" ref={emblaRef}>
      <div className="embla__container">
        {props.children.map((child) => (
          <div className="embla__slide">{child}</div>
        ))}
      </div>
    </div>
  );
};
