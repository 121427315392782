import { identityHeadersPromise } from "@/providers/IdentityProvider";

/**
 * Same as apiCall, except it does not catch errors
 * A convenience function for making API calls.
 *
 * @param  {string}   endpoint used in `/.netlify/functions/${endpoint}`
 * @param  {object}   options  fetch parameters
 * @param  {object}   data     if truthy, the options object's "body" field will
 *                             be overwritten with `body: JSON.stringify(data)`
 *
 * @return {object}            the return value from the server
 */
export const apiCallNoCatch = (endpoint, data, options) =>
  identityHeadersPromise().then((headers) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    window
      .fetch(`/.netlify/functions/${endpoint}`, {
        method: "GET",
        credentials: "include",
        headers,
        ...options,
        ...(data && { body: JSON.stringify(data) }),
      })
      .then(async (res) => {
        const { status } = res;
        const resData = await res.json();
        // eslint-disable-next-line no-console
        window.debug &&
          console.log(
            `%cstatus [${status}] response from ${endpoint} , data:`,
            "background:cadetblue; color:white;",
            resData,
          );
        return [resData, status];
      }),
  );

/**
 * A convenience function for making API calls.
 *
 * @param  {string}   endpoint used in `/.netlify/functions/${endpoint}`
 * @param  {object}   options  fetch parameters
 * @param  {object}   data     if truthy, the options object's "body" field will
 *                             be overwritten with `body: JSON.stringify(data)`
 *
 * @return {object}            the return value from the server
 */
export const apiCall = (endpoint, data, option) =>
  apiCallNoCatch(endpoint, data, option).catch((err) => console.error(err));
