import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { Link } from "@/components/LinkWithSavedState";

import { useOrders } from "@/providers/OrdersProvider";
import Loading from "@/components/Loading";
import { formatUsd } from "@/modules/utils";
import { getNiceDate } from "@/modules/niceDate";
// import { regenerateLinkCallback } from "modules/regenerateLinkCallback";
import { identityHeadersPromise } from "providers/IdentityProvider";

const renderItem = ({
  _key,
  fileTitle,
  category,
  price,
  filmTitle,
  filmSlug,
  thumbnail,
}) => (
  <tr key={_key} className="py05">
    <td
      className={cx("rel pr1 py075", {
        "asset__thumb-square": thumbnail,
      })}
    >
      <div className="rel x y">
        {thumbnail && (
          <img
            className="x y abs fill contain"
            src={thumbnail}
            alt={`thumbnail for ${fileTitle}`}
          />
        )}
      </div>
    </td>

    <td className="p1 m1">
      <h3 className="my025">{fileTitle}</h3>
    </td>

    <td className="order-cell">{formatUsd(price)}</td>

    <td className="order-cell">
      <div className="mb025">{category}</div>
      Film:{" "}
      <Link to={`/films/${filmSlug}`} className="film__link s16">
        {filmTitle}
      </Link>
    </td>
  </tr>
);

function Order(props) {
  const {
    _createdAt,
    total,
    items,
    orderNumber,
    downloadLink,
    _id: orderId,
  } = props;

  const date = getNiceDate(_createdAt);

  const [isRequestingNewDownloadLink, setIsRequestingNewDownloadLink] =
    useState(false);
  const [newDownloadLinkRequested, setNewDownloadLinkRequested] =
    useState(false);

  const requestNewDownloadLink = useCallback(async () => {
    const headers = await identityHeadersPromise();
    setIsRequestingNewDownloadLink(true);

    await window
      .fetch(
        `/.netlify/functions/request-new-download-link?orderid=${orderId}`,
        {
          method: "GET",
          credentials: "include",
          headers,
        },
      )
      .then((res) => res.json())
      .then((data) => {
        console.log(`%cdata`, "background:#bada55; color:#222;", data);
      })
      .then(() => setNewDownloadLinkRequested(true))
      .catch((err) => {
        console.error("error requesting new downloadLink", err);
      });
    setIsRequestingNewDownloadLink(false);
  }, [orderId]);

  return (
    <div className="container--s mxa mt1">
      <div className="f jcb aie">
        <div>
          <h1 className="mt0 mb025 p">
            Order <span className="h2">#</span>
            {orderNumber}
          </h1>
          <div className="s18">{date.long}</div>
        </div>
        <div className="ar a22">
          Total {formatUsd(total)} <br />
          {downloadLink ? (
            <button className="mt025 hover rel">
              <a
                href={downloadLink}
                target="_blank"
                rel="noopener noreferrer"
                className="abs fill z2"
              ></a>
              Download link
            </button>
          ) : newDownloadLinkRequested ? (
            <p className="mt025 mb0">New DownloadLink Requested</p>
          ) : (
            // <button
            //   onClick={requestNewDownloadLink}
            //   className="mt025 hover"
            //   disabled={isRequestingNewDownloadLink}
            // >
            //   Request new download link
            // </button>
            <></>
            //   <button
            //     onClick={() => regenerateLinkCallback(props._id)}
            //     className="mt025 hover"
            //   >
            //     regenerate 1-time download link
            //   </button>
          )}
        </div>
      </div>

      <hr className="p mt05 mb075" />

      <table className="mxa x">
        <tbody>{items.map(renderItem)}</tbody>
      </table>
    </div>
  );
}

function OrderContainer(props) {
  const { orderId } = props;
  const { getOrder } = useOrders();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    getOrder(orderId).then(setOrder);
  }, [getOrder, orderId]);

  return (
    <div>
      <div className={"x film mb1"}>
        <Link to="/orders" className="film__back if jcs aic">
          <svg
            fill="currentColor"
            className="ml1 rotate"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 55 20"
          >
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              points="43.3,0 55,10 43.3,20 42.4,19.1 52.2,10.7 0,10.7 0,9.3 52.2,9.3   42.4,0.9 43.3,0 "
            />
          </svg>
          <h5 className="p0 my0 pl1 bcb rel z1">Back</h5>
        </Link>
      </div>

      <Loading ready={!!order}>{order && <Order {...order} />}</Loading>
    </div>
  );
}

export default OrderContainer;
