import React from "react";
import cx from "classnames";
import { FaChevronRight } from "react-icons/fa";

import CreateUser from "./CreateUser";
import RegenerateLink from "./RegenerateLink";

const CREATE_USER = 0;
const REGENERATE_DOWNLOAD_LINK = 1;

const getInitialTab = () => {
  const orderId = new URLSearchParams(window.location.search).get(
    "regenerateOrder",
  );
  return orderId ? REGENERATE_DOWNLOAD_LINK : CREATE_USER;
};

function Admin() {
  const [activeTab, setActiveTab] = React.useState(getInitialTab);

  const renderButton = (label, panelId) => (
    <div
      role="button"
      className={cx("h4 button", {
        admin__nav__selected: activeTab === panelId,
      })}
      onClick={() => setActiveTab(panelId)}
    >
      {label}
    </div>
  );

  const renderPanel = (Component, panelId) => (
    <div
      className={cx("fill z0", {
        "hidden abs": activeTab !== panelId,
        z1: activeTab === panelId,
      })}
    >
      <Component />
    </div>
  );

  return (
    <div className="ac x container--l mxa auth__none admin__container f jcc rel">
      <nav className="admin__nav z3 bcb">
        <div className="abs top right left x aic jcc admin__nav__indicator">
          <FaChevronRight className="" />
        </div>

        {renderButton("Create User", CREATE_USER)}
        {renderButton("Regenerate Download Link", REGENERATE_DOWNLOAD_LINK)}
      </nav>

      <div className="x rel admin__content">
        {renderPanel(CreateUser, CREATE_USER)}
        {renderPanel(RegenerateLink, REGENERATE_DOWNLOAD_LINK)}
      </div>
    </div>
  );
}

export default Admin;
