import React from "react";

function Terms(props) {
  return (
    <div>
      <h4>Terms and Conditions of Use</h4>
      <p>
        <strong>
          PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING
          THIS SITE.
        </strong>
      </p>
      <p>
        A24 Films LLC and its related entities (“A24” or “we” or “us”) own and
        operate the www.press.a24films.com web site (the “Web Site”).  By using
        the Web Site or any material on the Web Site, including any electronic
        press kit material, you signify your assent to these Terms and
        Conditions of Use (“Terms and Conditions”).  If you do not agree to all
        of these Terms and Conditions, do not use the Web Site.  We may revise
        and update these Terms and Conditions at any time.  Your continued usage
        of the Web Site will mean you accept those changes.
      </p>
      <ol>
        <li>
          <strong>Use of Material.</strong>
          <ul className="alpha">
            <li>
              The content, including without limitation, text, artwork,
              photographs, videos, etc. (“Material”) posted on the Web Site is
              owned or controlled by A24, its affiliates, its licensors, or its
              third-party partners (each, an “Owner”, collectively “Owners”) and
              is protected by copyright, trade dress, moral rights, trademark
              and other laws relating to the protection of intellectual property
              in the United States and internationally.  The Material may be
              used by users who have registered on the Web Site solely as a
              promotional tool to promote the exhibition of the corresponding
              program, motion picture, film or other audio visual work (“Work”),
              subject to the terms and restrictions set forth herein and any
              specific terms and restrictions set forth on the Web Site in
              connection with the Material (“Material-Specific Restrictions”). 
              To the extent of any conflict between the terms and restrictions
              set forth herein and the Material-Specific Restrictions, the
              Material-Specific Restrictions shall control.  No other uses are
              permitted without the prior written consent of the relevant Owner
              of the Material, if different. Use of the material in violation of
              the foregoing may result in civil and/or criminal proceedings and
              penalties.  All rights not expressly granted herein are reserved
              to the relevant Owner.
            </li>
            <li>
              Any use, duplication, display, preparation of derivative works
              based upon, or distribution of the Material other than as
              expressly permitted herein or pursuant to the written
              authorization of the relevant Owner is strictly prohibited.  You
              may not lease or sell the Material in whole or in part. Each Owner
              reserves its rights to pursue any and all legal remedies to
              terminate any unauthorized use, duplication, display, preparation
              of derivative works based upon, or distribution of the Material.{" "}
            </li>
            <li>
              The Material cannot be used for parody or other distortion of any
              plot, theme, idea, image, likeness, character or name contained in
              any Work or the Material.  Any use of the Material not expressly
              provided for hereunder must be approved by A24 and the relevant
              Owner, in its sole discretion.  The permission granted herein to
              use the Material may be revoked at any time by A24 or the relevant
              Owner, and shall expire 12 months from its initial release date. 
            </li>
            <li>
              Video posted on any Internet site must be streaming only.
              Archiving is strictly prohibited and video must be removed within
              12 months of the release date. Unless otherwise specified on the
              Web Site, any clip or clips used within a segment or posted as
              stand-alone material are limited to under 4 minutes. Only the
              listed downloadable file versions are approved for use. 
            </li>
            <li>
              The Material is subject to change and your ability to access
              and/or use the Material may be terminated without notice in the
              sole discretion of A24 or the relevant Owner. Nothing herein shall
              constitute a representation or warranty by A24 or any Owner that
              the Material shall be error free.
            </li>
            <li>
              If you violate any of these Terms and Conditions, without limiting
              any rights or remedies available to A24 or an Owner, your
              permission to use the Material and access the Web Site
              automatically terminates and you must immediately destroy any
              copies you have made of any portion of the Material and
              discontinue accessing the Web Site.
            </li>
          </ul>
        </li>
        <li>
          <strong>Use of the Web Site.</strong>
          <ul className="alpha">
            <li>
              In your use of the Web Site, you agree to act responsibly in a
              manner demonstrating the exercise of good judgment.  For example
              and without limitation, you agree not to (i) use the Web Site for
              any purpose in violation of local, state, national, or
              international laws, (ii) infringe or violate the rights of any
              third party, including without limitation, intellectual property,
              privacy, publicity or contractual rights, (iii) engage in
              spidering, “screen scraping,” “database scraping,” harvesting of
              e-mail addresses, wireless addresses or other contact or personal
              information, or any other automatic means of obtaining lists of
              users or other information from or through the Web Site or the
              services offered on or through the Web Site, including without
              limitation any information residing on any server or database
              connected to the Web Site or the services offered on or through
              the Web Site, (iv) interfere with, interrupt, damage, disable,
              overburden, or impair the Web Site or the services made available
              on or through the Web Site, including, without limitation, through
              the use of viruses, cancel bots, Trojan horses, harmful code,
              flood pings, denial of service attacks, packet or IP spoofing,
              forged routing or electronic mail address information or similar
              methods or technology, (v) attempt to gain unauthorized access to
              other computer systems through the Web Site, (vi) linking to the
              Web Site or any Material displayed or accessible through the Web
              Site, or (vii) assist any third party in doing any of the
              foregoing.
            </li>
            <li>
              You further agree that you shall not attempt (or permit,
              authorize, encourage or support anyone else’s attempt) to
              circumvent, reverse engineer, decrypt, or otherwise alter or
              interfere with the Web Site or the Web Site’s services, or make
              any unauthorized use thereof. You agree that you shall not use the
              Web Site in any manner that could damage, disable, overburden, or
              impair the Web Site or interfere with any other party’s use and
              enjoyment of the Web Site or any of its services. You shall not
              obtain or attempt to obtain any materials or information through
              any means not intentionally made publicly available or provided
              for through the Web Site.
            </li>
          </ul>
        </li>
        <li>
          <strong>A24 Accounts.</strong>
          <ul className="alpha">
            <li>
              In order to access some features of the Web Site, including the
              ability to download Material, you will have to register for
              an A24 account.  When registering, you must provide accurate and
              complete information. You may never use another’s account without
              permission.  It is your sole responsibility to (1) control the
              dissemination and use of passwords; (2) authorize, monitor, and
              control access to and use of your A24 account and password; (3)
              promptly inform us in writing of any need to deactivate a
              password.  You grant A24 and all other persons or entities
              involved in the operation of the Web Site the right to transmit,
              monitor, retrieve, store, and use your information in connection
              with the operation of the Web Site. A24 cannot and does not assume
              any responsibility or liability for any information you submit, or
              your or third parties’ use or misuse of information transmitted or
              received using A24 tools and services.  You must
              notify A24 immediately of any breach of security or unauthorized
              use of your account.
            </li>
            <li>
              Although A24 will not be liable for your losses caused by any
              unauthorized use of your account, you may be liable for the losses
              of A24 or others due to such unauthorized use.
            </li>
          </ul>
        </li>
        <li>
          <strong>Trademarks.</strong> A24 Trademarks, the A24 logo, and any
          other product or service name or slogan contained in the Web Site are
          trademarks of A24 and its suppliers or licensors, and may not be
          copied, imitated or used, in whole or in part, without the prior
          written permission of A24 or the applicable trademark holder. You may
          not use metatags or any other “hidden text” utilizing “A24” or any
          other name, trademark or product or service name of A24 without our
          prior written permission. In addition, the look and feel of the Web
          Site, including all page headers, custom graphics, button icons and
          scripts, is the service mark, trademark and/or trade dress of A24 and
          may not be copied, imitated or used, in whole or in part, without our
          prior written permission. All other trademarks, registered trademarks,
          product names and company names or logos mentioned in the Web Site are
          the property of their respective owners. Reference to any products,
          services, processes or other information, by trade name, trademark,
          manufacturer, supplier or otherwise does not constitute or imply
          endorsement, sponsorship or recommendation thereof by us.
        </li>
        <li>
          <strong>Liability of A24 and its Licensors.</strong>
          <ul className="alpha">
            <li>
              The use of the Web Site and the Material is at your own risk.
            </li>
            <li>
              When using the Web Site, information will be transmitted over a
              medium that may be beyond the control and jurisdiction of A24 and
              the Owners.  Accordingly, A24 and the Owners assume no liability
              for or relating to the delay, failure, interruption, or corruption
              of any data or other information transmitted in connection with
              use of the Web Site.
            </li>
            <li>
              THE WEB SITE, INCLUDING WITHOUT LIMITATION THE MATERIAL, ARE
              PROVIDED "AS IS" AND A24 AND THE OWNERS AND THEIR RESPECTIVE
              DIRECTORS, EMPLOYEES, CONTENT PROVIDERS, AGENTS AND AFFILIATES
              EXCLUDE, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ANY
              WARRANTY, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY
              IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY OR
              FITNESS FOR A PARTICULAR PURPOSE. NEITHER A24 NOR THE OWNERS WILL
              BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE
              WEB SITE OR THE MATERIAL, OR THE UNAVAILABILITY OF THE SAME,
              INCLUDING, BUT NOT LIMITED TO LOST PROFITS, AND DIRECT, INDIRECT,
              INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES. THE FUNCTIONS
              EMBODIED ON OR IN THE MATERIALS OF THIS WEB SITE ARE NOT WARRANTED
              TO BE UNINTERRUPTED OR WITHOUT ERROR. YOU, NOT A24 OR THE OWNERS,
              ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR
              CORRECTION DUE TO YOUR USE OF THIS WEB SITE OR THE MATERIAL. WE
              MAKE NO WARRANTY THAT THE WEB SITE OR THE MATERIAL IS FREE FROM
              INFECTION BY VIRUSES OR ANYTHING ELSE THAT HAS CONTAMINATING OR
              DESTRUCTIVE PROPERTIES. A24 uses reasonable efforts to ensure the
              accuracy, correctness and reliability of the Material, but neither
              we nor the Owners make any representations or warranties as to the
              Material’s accuracy, correctness, reliability, completeness,
              currentness, or timeliness of the Material, software, text,
              graphics, links, or communications provided on or through the use
              of the Web Site.
            </li>
            <li>
              In no event shall A24, the Owners, or any third parties mentioned
              on the Web Site be responsible for any claims if these Terms and
              Conditions are not followed or liable for any incidental and
              consequential damages, personal injury/wrongful death, lost
              profits, or damages resulting from lost data or business
              interruption resulting from the use of or inability to use the Web
              Site or the Material, whether based on warranty, contract, tort,
              or any other legal theory, and whether or not A24, the Owners, or
              any third parties mentioned on the Web Site are advised of the
              possibility of such damages.  Given that the Web Site and Material
              are provided to you on a gratuitous basis, the liability of A24,
              the Owners, and any third parties mentioned on the Web Site shall
              be limited to actual damages incurred by you, not to exceed U.S.
              $1.  A24, the Owners, or any third parties mentioned on the Web
              Site are not liable for any personal injury, including death,
              caused by your use or misuse of the Web Site or the Material. Any
              claims arising in connection with your use of the Web Site or any
              Material, must be brought within one (1) year of the date of the
              event giving rise to such action occurred. Your remedies under
              these Terms and Conditions are exclusive and are limited to those
              expressly provided for in these Terms and Conditions.
            </li>
          </ul>
        </li>
        <li>
          <strong>User Submissions.</strong>  By sending to A24 via email any
          information, creative works, demos, ideas, suggestions, concepts,
          methods, systems, designs, plans, techniques, business information,
          inventions, how-to or product information or material, you: (i) agree
          such submission is non-confidential for all purposes, (ii)
          grant A24 an unrestricted, irrevocable license to use, reproduce,
          display, perform, modify, transmit and distribute materials or
          information, and (iii) represent and warrant that you own or otherwise
          control all of the rights to such information or materials and
          that A24 is free to use ideas, concepts, know-how or techniques that
          you send us for any purpose.  A24 may sublicense its rights through
          multiple tiers of sublicenses.  Notwithstanding the foregoing, the
          personal information you submit to A24 is governed by the A24 Privacy
          Policy.  Please review our Privacy Policy for a complete description
          of how we handle personal information submitted in the process of
          ordering products or registering on our site.  To the extent there is
          an inconsistency between this Agreement and the A24 Privacy Policy,
          these Terms and Conditions shall govern.
        </li>
        <li>
          <strong>Rights Reserved.</strong>  A24 reserves the right to refuse
          service, terminate your account or your access to the Web Site or any
          of the Web Site’s services or features, and/or refuse to provide
          materials, including, without limitation, if A24 believes that your
          conduct violates applicable law or is harmful to the interests
          of A24 or the Owners.
        </li>
        <li>
          <strong>Links to Other Sites.</strong> A24 may provide links to
          third-party web sites. A24 does not recommend and does not endorse the
          content on any third-party websites. A24 is not responsible for the
          content of linked third-party sites, and does not make any
          representations regarding their content or accuracy. Your use of
          third-party websites is at your own risk and subject to the terms and
          conditions of use for such sites.
        </li>
        <li>
          <strong>Indemnity.</strong> You agree to defend, indemnify, and
          hold A24, its officers, directors, employees, agents, and the Owners
          harmless from and against any claims, actions or demands, liabilities
          and settlements including without limitation, reasonable legal and
          accounting fees, resulting from, or alleged to result from: (i) your
          use of and access to the Web Site and/or the Material; or (ii) your
          violation of any term or condition of these Terms and Conditions. This
          defense and indemnification obligation will survive these Terms and
          Conditions and your use of the Web Site.
        </li>
        <li>
          <strong>General</strong>
          <ul className="alpha">
            <li>
              A24 is based in New York, New York, in the United States of
              America.   A24 makes no claims that the Web Site and the Material
              are appropriate or may be downloaded outside of the United States
              for all Web Site content.  Access to or use of the Material may
              not be legal or authorized by certain persons or in certain
              countries due to the applicable laws in a country or due to the
              terms and conditions set forth therein and/or Material-Specific
              Provisions.  If you access the Web Site from outside the United
              States, you do so at your own risk and are responsible for
              compliance with the applicable laws of the jurisdiction in which
              you are accessing the Web Site and Material and for compliance
              with all of your obligations under these Terms and Conditions.
            </li>
            <li>
              The following provisions survive the expiration or termination of
              the Terms and Conditions for any reason whatsoever: Liability,
              User Submissions, Rights Reserved, Indemnity, Jurisdiction, and
              Complete Agreement.
            </li>
          </ul>
        </li>
        <li>
          <strong>Jurisdiction</strong>
          <ul className="alpha">
            <li>
              You expressly agree that exclusive jurisdiction for any dispute
              with A24, or in any way relating to your use of the Web Site,
              resides in the federal and state courts of New York, New York, and
              you further agree and expressly consent to the exercise of
              personal jurisdiction of such courts in connection with any such
              dispute including any claim involving A24 or its affiliates,
              subsidiaries, employees, contractors, officers, directors,
              telecommunication providers, and content providers.
            </li>
            <li>
              These Terms and Conditions are governed by the internal
              substantive laws of the State of New York, without respect to its
              conflict of laws principles. If any provision of these Terms and
              Conditions is found to be invalid by any court having competent
              jurisdiction, the invalidity of such provision shall not affect
              the validity of the remaining provisions of these Terms and
              Conditions, which shall remain in full force and effect. No waiver
              of any of these Terms and Conditions shall be deemed a further or
              continuing waiver of such term or condition or any other term or
              condition.
            </li>
          </ul>
        </li>
        <li>
          <strong>Complete Agreement.</strong> Except as expressly provided in a
          particular “legal notice” on the Web Site, these Terms and Conditions
          and the A24 Privacy Policy constitute the entire agreement between you
          and A24 with respect to the use of the Web Site and the Material.
        </li>
      </ol>
      <p>
        Thank you for your cooperation. Questions or comments regarding the Web
        Site should be submitted to 
        <a href="mailto:info@a24films.com">info@a24films.com</a>.
      </p>
    </div>
  );
}

export default Terms;
