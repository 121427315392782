import React from "react";

import {
  Field,
  IntlPhoneInput,
  CountrySelect,
  TerritorySelect,
} from "./Inputs";
import AuthorizationIDField from "./AuthorizationIDField";
import Accordion from "../Accordion";

export const Required = () => (
  <>
    <div className="mb05 semi akz-e py025 al s22">Required Information</div>

    <div className="pb05">
      <div className="x input__row">
        <Field field="name" label="Name*" required />
        <Field field="email" label="Email*" required disabled />
      </div>

      <div className="x input__row">
        <Field field="company" label="Company*" required />
        <Field field="country" label="Country*" required>
          <CountrySelect />
        </Field>
      </div>

      <Field
        field="companyId"
        label="Client ID*"
        required
        className="company-id"
      />
    </div>
  </>
);

export const AdditionalInfo = () => (
  <Accordion
    label="Additional Information (optional)"
    labelClass="mb075 al s22"
    className="al"
  >
    <div className="pb1">
      <div className="x f input__row">
        <Field field="street" label="Address" />
        <Field
          field="street2"
          label="Apartment/Suite"
          className="input__small"
        />
      </div>

      <div className="x f input__row">
        <Field field="city" label="City" />
        <Field field="zip" label="Zip" className="input__small" />
      </div>

      <div className="x f input__row">
        <Field field="province" label="Province" />
        <Field
          field="territory"
          label="Territory"
          className="input__small territory"
        >
          <TerritorySelect />
        </Field>
      </div>

      <div className="x input__row">
        <Field field="phone" label="Phone">
          <IntlPhoneInput field="phone" placeholder="Phone" />
        </Field>
        {/*     <Field field="cell" label="Cell"> */}
        {/*       <IntlPhoneInput field="cell" placeholder="Cell" /> */}
        {/*     </Field> */}
        <Field field="fax" label="Fax">
          <IntlPhoneInput field="fax" placeholder="Fax" />
        </Field>
      </div>

      <div className="x input__row">
        <Field field="website" label="Website" />
        <Field
          field="reference"
          label="Reference (PO number)"
          className="input__reference"
        />
      </div>

      <Field field="note" label="Note" type="textarea" />
    </div>
  </Accordion>
);

export const AuthorizationID = () => (
  <Accordion
    label="Authorization ID (optional)"
    labelClass="mb075 al s22 block"
    className="al inline-block"
  >
    <div className="pb1">
      <AuthorizationIDField />
    </div>
  </Accordion>
);
