import React from "react";
import { Link } from "@/components/LinkWithSavedState";
import { IoIosMenu, IoMdCart } from "react-icons/io";

import { get } from "modules/utils";
import { useIdentity } from "../providers/IdentityProvider";
import { useCart } from "../providers/CartProvider";

function Header(props) {
  const { isLoggedIn, logoutUser, user } = useIdentity();
  const cart = useCart();

  const logout = React.useCallback(
    (event) => {
      event.preventDefault();
      try {
        logoutUser();
      } catch (e) {
        window.location.reload();
      }
    },
    [logoutUser],
  );

  const roles = get(user, "app_metadata.roles", null);
  const isAdmin = roles && roles.includes("admin");

  const email = get(user, "email", null);
  const userName = get(user, "user_metadata.full_name", null);

  return (
    <header className={`header container--l mxa ${props.className || ""}`}>
      <div className="p15 outer x f fw jcb aic">
        <h1 style={{ margin: 0 }}>
          <Link to="/">
            <svg
              className="header__logo"
              width="380px"
              height="156px"
              viewBox="0 0 380 156"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fill="currentColor" fillRule="nonzero">
                  <path d="M325.2,113.5 L272,113.5 L325.2,54.4 L325.2,113.5 L325.2,113.5 Z M368.7,113.5 L368.7,0 L262.8,117.6 L181.5,117.6 C178,117.6 175.2,114.8 175.2,111.3 C175.2,108.9 176.5,106.9 178.4,105.8 C178.5,105.8 178.5,105.7 178.6,105.7 C186.8,101.5 194.7,99.3 199.2,98 L200.6,97.6 C203.3,96.8 206.7,96.1 210.2,95.3 C211.4,95 212.7,94.7 214,94.5 C235.5,89.6 264.6,81.2 264.6,46.9 C264.6,39.3 262.7,31.6 258.3,24.7 C251.3,13.7 238.1,4.8 216.5,2.2 C212.3,1.7 207.7,1.4 202.8,1.4 C169.3,1.4 146.7,19 145.9,45.4 C145.9,45.9 145.9,46.3 145.9,46.8 C145.9,61.4 157.7,73.2 172.3,73.2 C186.9,73.2 198.7,61.3 198.7,46.8 C198.7,32.2 186.9,20.4 172.3,20.4 C171.5,20.4 170.7,20.4 169.9,20.5 C169.9,20.5 169.8,20.5 169.8,20.5 C164.4,21 159.4,23.2 155.5,26.4 C158.5,21.7 162.6,17.7 167.6,14.4 C167.7,14.4 167.7,14.3 167.8,14.3 C176.8,8.5 188.8,5.3 202.9,5.3 C204.4,5.3 205.9,5.3 207.3,5.4 C207.6,5.4 207.9,5.4 208.2,5.4 C211.3,5.8 213.7,8.4 213.7,11.6 L213.7,84.8 C213.7,88.2 211.3,91.1 208.2,91.9 C208,91.9 207.9,92 207.7,92 C204.7,92.6 202,93.3 199.6,94 L198.3,94.4 C191.8,96.2 178.3,100 166.4,109.1 C162.2,112.3 158.6,115.8 155.5,119.7 C147.7,129.6 143.8,141.7 143.8,155.8 L228.7,155.8 L263,117.7 L325.3,117.7 L325.3,155.8 L368.8,155.8 L368.8,117.7 L379.7,117.7 L379.7,113.6 L368.7,113.6 L368.7,113.5 Z M22,118.3 L48.8,61 L75.5,118.3 L22,118.3 Z M57.7,32.2 C52.3,43.5 41.1,44.7 41.1,44.7 L46.5,56.2 L46.5,56.3 L46.5,56.3 L0,155.7 L4.5,155.7 L20.1,122.4 L77.4,122.4 L92.9,155.7 L140.9,155.7 L70.6,4.7 L57.7,32.2 L57.7,32.2 Z" />
                </g>
              </g>
            </svg>
            <span className="small ml1 akz-e caps">International</span>
          </Link>
        </h1>

        {isLoggedIn && (
          <>
            <div
              className="mxa mr05 link hover z1 p05"
              onClick={cart.open}
              aria-label="Open Cart"
              role="button"
            >
              <IoMdCart />
            </div>

            <div className="header__menu p025 rel">
              <IoIosMenu />

              <div className="header__menu__content abs right bottom px05 ar">
                <Link className="block p025" to="/orders">
                  Order History
                </Link>
                {isAdmin && (
                  <a className="block pb025" href="/admin">
                    <span role="img" aria-label="key">
                      🔑{" "}
                    </span>
                    Admin Tools
                  </a>
                )}
                <a className="block p025" href="/" onClick={logout}>
                  Logout
                </a>
              </div>

              {userName ? (
                <div className="header__email ar px05">
                  Logged in as: {userName}
                </div>
              ) : (
                email && (
                  <div className="header__email ar px05">
                    Logged in as: {email}
                  </div>
                )
              )}
            </div>
          </>
        )}
      </div>
    </header>
  );
}

export default Header;
