import { globalHistory } from "@reach/router";

import { isSessionStorageAvailable, safeParse } from "modules/utils";

export const ORIGIN_KEY = "ORIGIN_KEY";
const STORAGE_KEY = "page-state-stack";

let pageStateStack = [
  // there's no state on first landing,
  // but there IS a key on page reload
  { key: window.history?.state?.key || ORIGIN_KEY },
];

if (isSessionStorageAvailable()) {
  const _pageStateStack = safeParse(sessionStorage.getItem(STORAGE_KEY));
  if (_pageStateStack) {
    pageStateStack = _pageStateStack;
  }
}

const updateStorage = () =>
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(pageStateStack));

/**
 * Call this to prune the history from the previous key forward.
 * Only call this on push actions, because on a push action,
 * the forward stack is changing.
 */
const pruneHistory = (previousKey) => {
  const lastKeyInStack = pageStateStack[pageStateStack.length - 1]?.key;

  if (previousKey && lastKeyInStack !== previousKey) {
    const index = pageStateStack.findIndex(({ key }) => key === previousKey);
    pageStateStack = pageStateStack.slice(0, index + 1);
  }
};

let previousKey;
let globalListenerTimeout;
globalHistory.listen(({ action, location }) => {
  clearTimeout(globalListenerTimeout);
  globalListenerTimeout = setTimeout(() => {
    const key = location?.state?.key || ORIGIN_KEY;

    // on pop actions, there will already an entry with this key,
    // so we're only concerned with push actions
    if (action.toUpperCase() !== "POP") {
      pruneHistory(previousKey);
      pageStateStack.push({ key });
      updateStorage();
    }
    previousKey = key;
  }, 240);
});

const isObject = (obj) =>
  typeof obj === "object" && !Array.isArray(obj) && obj !== null;

export const setPageState = (key, state) => {
  const stateItem = pageStateStack.reverse().find((item) => item.key === key);
  if (!stateItem) {
    pageStateStack.push({ key, state, href: window.location.href });
    updateStorage();
    return;
  }

  stateItem.state = state;
};

export const getCurrentPageState = () => {
  const key = window.history.state?.key || ORIGIN_KEY;
  const element = pageStateStack.reverse().find((item) => item.key === key);
  return isObject(element?.state) ? element.state : {};
};
