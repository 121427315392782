import React, { useState, useCallback, useEffect } from "react";
import BlockContent from "@sanity/block-content-to-react";
import cx from "classnames";

import { Link, navigate } from "@/components/LinkWithSavedState";
import { get, groupBy } from "@/modules/utils";
import { identityHeadersPromise } from "@/providers/IdentityProvider";
import { useCart } from "@/providers/CartProvider";
import Image from "@/components/Image";
import Accordion, { AccordionGroup } from "@/components/Accordion";
import AssetThumb from "./AssetThumb";

const Loading = (props) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => setLoaded(true), 300);
  }, []);

  return <div className={cx("film__single", { loaded })}>{props.children}</div>;
};

const groupAssets = (assets) => {
  const byCategory = groupBy(assets, "category");
  return byCategory.map((group) => groupBy(group, "subCategory"));
};

function Film(props) {
  const [film, setFilm] = useState(null);
  const [assets, setAssets] = useState([]);
  const { addItems } = useCart();

  useEffect(() => {
    identityHeadersPromise()
      .then((headers) =>
        window.fetch(`/.netlify/functions/get-films?slug=${props.slug}`, {
          method: "GET",
          credentials: "include",
          headers,
        }),
      )
      .then((res) => res.json())
      .then((data) => {
        const _film = get(data, "films.0");

        if (_film) {
          setFilm(_film);

          if (_film.assets) {
            setAssets(groupAssets(_film.assets));
            window.__assets = JSON.stringify(_film.assets);
          }
        } else {
          navigate("/", { replace: true });
        }
      })
      .catch((err) => console.log("film fetch error:", err));
  }, [props.slug]);

  const addSubcategoryToCart = useCallback(
    (subCatGroup) => {
      const assetIds = subCatGroup.map((a) => a._id);

      if (assetIds.length > 0) {
        addItems(assetIds);
      }
    },
    [addItems],
  );

  return (
    <div>
      {film && (
        <Loading>
          <div className="x film">
            <Link to="/" back className="film__back if jcs aic">
              <svg
                fill="currentColor"
                className="ml1 rotate"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 55 20"
              >
                <polygon
                  fillRule="evenodd"
                  clipRule="evenodd"
                  points="43.3,0 55,10 43.3,20 42.4,19.1 52.2,10.7 0,10.7 0,9.3 52.2,9.3   42.4,0.9 43.3,0 "
                />
              </svg>
              <h5 className="p0 my0 pl1 bcb rel z1">Back</h5>
            </Link>
          </div>

          <div className="x py1 film__content">
            <div className="f jcb ais">
              <div className="rel x film__poster">
                {film.image && (
                  <React.Fragment>
                    <div className="abs z1 top left x y"></div>
                    <Image
                      className="x obj-fit"
                      source={film.image}
                      imageId={film.poster.asset._ref}
                      size={400}
                      alt={film.title}
                    />
                  </React.Fragment>
                )}
              </div>

              <div className="film__content-inner x">
                <h1 className="m0 p0">{film.title}</h1>
                <div className="container--xs">
                  <BlockContent blocks={film.overview} />
                </div>

                <div className="container--xs">
                  <AccordionGroup>
                    {assets.map((categoryGroup = []) => {
                      const categoryName = get(
                        categoryGroup,
                        "0.0.category",
                        "Files",
                      );

                      return (
                        <Accordion
                          key={categoryName}
                          id={categoryName}
                          label={<h3 className="m0 p0 caps">{categoryName}</h3>}
                          labelClass="s22"
                        >
                          {categoryGroup.map((subCatGroup = []) => {
                            const subCatName = get(
                              subCatGroup,
                              "0.subCategory",
                              "",
                            );

                            return (
                              <Accordion
                                key={`${categoryName}_${subCatName}`}
                                id={`${categoryName}_${subCatName}`}
                                label={subCatName}
                                labelClass="s18 py05"
                              >
                                <div
                                  className="f jcs aic mb05 mr1 hover block asset__add-to-cart button caps akz black"
                                  id="checkout-button-sku_G9OyKbsxZ5RNDI"
                                  onClick={() =>
                                    addSubcategoryToCart(subCatGroup)
                                  }
                                >
                                  Add all {subCatName} assets to cart
                                  <div className="asset__add-to-cart__arrow ml05">
                                    <svg
                                      fill="currentColor"
                                      version="1.1"
                                      id="Layer_1"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 55 20"
                                    >
                                      <polygon
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        points="43.3,0 55,10 43.3,20 42.4,19.1 52.2,10.7 0,10.7 0,9.3 52.2,9.3   42.4,0.9 43.3,0 "
                                      />
                                    </svg>
                                  </div>
                                </div>

                                <div className="f fw jcs aic film__images-container">
                                  {subCatGroup[0] &&
                                    subCatGroup[0].displayType === "list" && (
                                      <div className="x films__header"></div>
                                    )}

                                  {subCatGroup.map((asset) => (
                                    <AssetThumb
                                      key={asset._id}
                                      {...asset}
                                      filmSlug={props.slug}
                                    />
                                  ))}
                                </div>
                              </Accordion>
                            );
                          })}
                        </Accordion>
                      );
                    })}
                  </AccordionGroup>
                </div>
              </div>
            </div>
          </div>
        </Loading>
      )}
    </div>
  );
}

export default Film;
