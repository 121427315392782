const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const usdFormatterShort = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export const formatUsd = (x) => usdFormatter.format(x);
export const formatUsdShort = (x) => {
  const noCents = (100 * x) % 100 === 0;
  return (noCents ? usdFormatterShort : usdFormatter).format(x);
};

export const isDev = process.env.NODE_ENV === "development";

/**
 * This tiny/quick alternative to Lodash's get method has one limitation:
 * it only supports dot notation.
 *
 * So treat arrays like objects, instead of 👎'arr[0]', use 'arr.0'👍
 *
 * @param  {object} object       the object in which you're looking for the value
 * @param  {string} path         the dot notation path to the value
 * @param  {mixed}  defaultValue if this function resolves to 'undefined'
 *                               this value is returned instead
 *
 * @return {mixed}               the value at the path, or defaultValue
 */
export function get(_object, path, defaultValue) {
  let object = _object;
  if (!object) {
    return defaultValue;
  }

  const parts = path.split(".");

  for (let i = 0; i < parts.length; i++) {
    object = object[parts[i]];

    if (typeof object === "undefined") {
      return defaultValue;
    }
  }

  return object;
}

export function safeParse(str, defaultValue = {}) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return defaultValue;
  }
}

let isAvailableLocal = null;
let isAvailableSession = null;
export function isLocalStorageAvailable() {
  if (isAvailableLocal === null) {
    try {
      const uid = new Date().getTime().toString();
      localStorage.setItem(uid, uid);
      isAvailableLocal = localStorage.getItem(uid) === uid;
      localStorage.removeItem(uid);
    } catch (exception) {
      isAvailableLocal = false;
    }
  }
  return isAvailableLocal;
}

export function isSessionStorageAvailable() {
  if (isAvailableSession === null) {
    try {
      const uid = new Date().getTime().toString();
      sessionStorage.setItem(uid, uid);
      isAvailableSession = sessionStorage.getItem(uid) === uid;
      sessionStorage.removeItem(uid);
    } catch (exception) {
      isAvailableSession = false;
    }
  }
  return isAvailableSession;
}

// usage: const obj = groupBy(list, "group");
export function groupBy(collection, property) {
  let val;
  let index;

  const values = [];
  const result = [];

  for (let i = 0; i < collection.length; i++) {
    val = collection[i][property];
    index = values.indexOf(val);
    if (index > -1) {
      result[index].push(collection[i]);
    } else {
      values.push(val);
      result.push([collection[i]]);
    }
  }

  return result;
}

export const compose = (...fns) =>
  fns.reduce(
    (f, g) =>
      (...args) =>
        f(g(...args)),
  );
export const composeAsync =
  (...fns) =>
  (...args) =>
    fns.reduceRight(
      (chain, func) => chain.then(func),
      Promise.resolve(...args),
    );
