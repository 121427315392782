import { useCallback, useEffect } from "react";

import { isSessionStorageAvailable, safeParse } from "modules/utils";

export const useSessionStoredForm = (form, init) => {
  useEffect(() => {
    if (isSessionStorageAvailable() && typeof init === "function") {
      const savedFormData = safeParse(
        sessionStorage.getItem("saved-checkout-form-data"),
      );
      if (savedFormData) {
        init(savedFormData);
      }
    }
  }, [init]);

  useEffect(() => {
    if (isSessionStorageAvailable()) {
      sessionStorage.setItem("saved-checkout-form-data", JSON.stringify(form));
    }
  }, [form]);

  const clearStoredData = useCallback(() => {
    sessionStorage.removeItem("saved-checkout-form-data");
  }, []);

  return clearStoredData;
};
