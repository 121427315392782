import React, { useState, useEffect } from "react";
import { Link } from "@/components/LinkWithSavedState";
import cx from "classnames";

import { useIdentity } from "@/providers/IdentityProvider";
import { useOrders } from "@/providers/OrdersProvider";
import Loading from "@/components/Loading";
import { getNiceDate } from "@/modules/niceDate";
import { formatUsd } from "@/modules/utils";

const HeaderCell = ({ children, className }) => (
  <div className={cx("orders__col ac", className)}>
    <h5 className="caps akz-e">{children}</h5>
  </div>
);

const BodyCell = ({ children, className }) => (
  <div className={cx("orders__col ac akz-e orders__body", className)}>
    {children}
  </div>
);

function OrderHistory() {
  const { isLoggedIn } = useIdentity();
  const { getOrderHistory } = useOrders();
  const [orders, setOrders] = useState(null);
  const [emptyMessage, setEmptyMessage] = useState(" ");

  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    getOrderHistory().then(setOrders);
    setTimeout(() => setTimedOut(true), 2500);
  }, [getOrderHistory]);

  useEffect(() => {
    if (!isLoggedIn) {
      setEmptyMessage("Log in to see your orders");
    } else if (orders && orders.length > 0) {
      setEmptyMessage(null);
    } else {
      setEmptyMessage("You have no completed orders");
    }
  }, [isLoggedIn, orders]);

  return (
    <div>
      <Loading ready={!!orders || timedOut}>
        {emptyMessage ? (
          <h1>{emptyMessage}</h1>
        ) : (
          <div className="films__wrapper orders">
            <div className="films__header f jcs aic">
              <HeaderCell className="f jcc al pl05">Order</HeaderCell>
              <HeaderCell>Date</HeaderCell>
              <HeaderCell># Items</HeaderCell>
              <HeaderCell>Status</HeaderCell>
              <HeaderCell>Total</HeaderCell>
            </div>

            <div className="films__content x body">
              {orders.map((order, i) => {
                const { _createdAt, _id, total, items, orderNumber } = order;

                const date = getNiceDate(_createdAt);

                return (
                  <div className="py1 films__row x f jcs aic rel" key={_id}>
                    <BodyCell>
                      <small>#</small>
                      {orderNumber}
                    </BodyCell>
                    <BodyCell>{date.long}</BodyCell>
                    <BodyCell>
                      {items.length} item{items.length !== 1 && "s"}
                    </BodyCell>
                    <BodyCell className="s16 f jce aic">
                      {order.downloadLink
                        ? "Assets awaiting download"
                        : "Already accessed"}
                      <svg
                        fill="currentColor"
                        className="ml1"
                        version="1.1"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 55 20"
                      >
                        <polygon
                          fillRule="evenodd"
                          clipRule="evenodd"
                          points="43.3,0 55,10 43.3,20 42.4,19.1 52.2,10.7 0,10.7 0,9.3 52.2,9.3   42.4,0.9 43.3,0 "
                        />
                      </svg>
                    </BodyCell>
                    <BodyCell className="">{formatUsd(total)}</BodyCell>
                    <Link
                      to={`/orders/${_id}`}
                      className="abs z1 x y top left"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Loading>
    </div>
  );
}

export default OrderHistory;
