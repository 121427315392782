import React, { useCallback } from "react";
import { IoMdClose, IoMdTrash } from "react-icons/io";
import { Location } from "@reach/router";
import cx from "classnames";

import { Link } from "@/components/LinkWithSavedState";
import useClickOutside from "@/modules/useClickOutside";
import LoadingSpinner from "@/components/LoadingSpinner";
import { formatUsd } from "@/modules/utils";
import { useCart } from "@/providers/CartProvider";
import { CheckoutButton } from "./Checkout/CheckoutButton";

const Loader = () => (
  <div className="abs fill aic jcc z3 cart__item__loader">
    <LoadingSpinner />
  </div>
);

export const CartSummary = () => {
  const cart = useCart();

  const {
    addingCount,
    isLocked,
    isItemLoading,
    items = [],
    removeItem,
    openCheckout,
    isCheckoutOpen,
    total,
    discount,
    removeClientId,
  } = cart;

  const isEmpty = items.length === 0 && !addingCount;

  return isEmpty ? (
    <div className="f aic z2 jcc abs">
      <h2>Your cart is empty</h2>
    </div>
  ) : (
    <div className={cx("cart-summary", { "cart-locked": isLocked })}>
      <div className="f jcb mx1">
        <h3 className="mt075 mb05">Filename</h3>
        <h3 className="mt075 mb05">Price</h3>
      </div>

      <hr className="m1" />

      <ul className="cart__items">
        {items.map((item, i) => (
          <li
            className={cx("cart__item rel my075 mx1 p0", item.isLoading && "p")}
            key={item._id}
          >
            <div className="f jcb aic rel z2 bcob">
              <span className="z1">
                <div className="s18 mb025">{item.title}</div>
                {isLocked ? (
                  <p className="s16 m0 inline-block">{item.filmTitle}</p>
                ) : (
                  <Link
                    to={isLocked ? undefined : `/films/${item.filmSlug}`}
                    className="film__link s16"
                  >
                    {item.filmTitle}
                  </Link>
                )}
                &nbsp;
              </span>

              <span className="z1">
                &nbsp;
                <div className="cart__price s18">{formatUsd(item.price)}</div>
              </span>
            </div>

            <div
              role="button"
              className="abs f aic jce fill cart__item__delete s22"
            >
              <div
                className="bcw f aic jcc hover"
                onClick={() => removeItem(item && item._id)}
              >
                <IoMdTrash />
              </div>
            </div>

            {isItemLoading(item._id) && <Loader />}
          </li>
        ))}

        {[...Array(addingCount)].map((_, i) => (
          <li className="cart__item rel m075 p0" key={`loader_${i}`}>
            <Loader />
          </li>
        ))}
      </ul>

      <hr className="m1" />

      {discount && (
        <ul className="cart__items mb0">
          <li className="cart__item rel mt075 mx1 p0">
            <div className="f jcb aic rel z2 bcob">
              <span className="z1">
                <div className="s18 mb025">Code: {discount.code}</div>
                <div className="s16">{discount.name}</div>
              </span>

              <span className="z1">
                &nbsp;
                <div className="cart__price s18">
                  {discount.deduction && `(${formatUsd(discount.deduction)})`}
                </div>
              </span>
            </div>

            <div
              role="button"
              className="abs f aic jce fill cart__item__delete s22"
            >
              <div className="bcw f aic jcc hover" onClick={removeClientId}>
                <IoMdTrash />
              </div>
            </div>

            {isItemLoading("discount") && <Loader />}
          </li>
        </ul>
      )}

      <div className="f jcb aic mt025 mx1">
        <h3 className="">Total</h3>
        <h3 className="">{formatUsd(total)}</h3>
      </div>

      {!isCheckoutOpen && (
        <Location>
          {({ location }) => (
            <CheckoutButton
              items={items}
              className="mt05 mx1 al"
              location={location}
              onClick={openCheckout}
            />
          )}
        </Location>
      )}
    </div>
  );
};

function MiniCart(props) {
  const { close, isOpen, isCheckoutOpen, isLocked } = useCart();

  const closeIfCheckoutIsNotOpen = useCallback(() => {
    if (isOpen && !isCheckoutOpen) {
      close();
    }
  }, [isCheckoutOpen, isOpen, close]);
  const cartRef = useClickOutside(closeIfCheckoutIsNotOpen);

  return (
    <div
      className={cx("cart bcob z8 outer p15", {
        closed: !isOpen,
        "with-checkout": isCheckoutOpen,
        "cart-locked": isLocked,
      })}
      ref={cartRef}
    >
      <div className="f jcb">
        <h3 className="akz-e caps mx075">Cart</h3>
        <div
          onClick={close}
          className="f jcc aic cart__close z3 rel mxa my025 hover link"
          aria-label="Close cart"
          role="button"
          disabled={isLocked}
        >
          <IoMdClose />
        </div>
      </div>

      <CartSummary />
    </div>
  );
}

export default MiniCart;
