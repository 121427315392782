import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  createContext,
} from "react";
import { globalHistory } from "@reach/router";

import {
  getCurrentPageState,
  setPageState,
  ORIGIN_KEY,
} from "modules/pageStateStack";

const PageStateContext = createContext();

// there's no state on first landing,
// but there IS a key on page reload
let currentHistoryKey = window.history?.state?.key || ORIGIN_KEY;

const SavedPageState = ({ children }) => {
  const [pageStateOnPageLoad, setPageStateOnPageLoad] = useState(
    getCurrentPageState()
  );

  useEffect(() => {
    globalHistory.listen(({ location, action }) => {
      const newKey = location?.state?.key || ORIGIN_KEY;

      // this helps protect against the globalListen's double firing
      // when navigate(-1) is used
      if (newKey === currentHistoryKey) return;

      currentHistoryKey = newKey;

      const currentPageState = getCurrentPageState();
      setPageStateOnPageLoad(currentPageState);
    });
  }, []);

  return (
    <PageStateContext.Provider value={pageStateOnPageLoad}>
      {children}
    </PageStateContext.Provider>
  );
};

export const usePageState = () => {
  const pageStateOnPageLoad = useContext(PageStateContext);

  const setSectionOpen = useCallback((sectionName, isOpen) => {
    const currentPageState = getCurrentPageState();
    setPageState(currentHistoryKey, {
      ...currentPageState,
      [sectionName]: isOpen,
    });
  }, []);

  return [pageStateOnPageLoad, setSectionOpen];
};

export default SavedPageState;
