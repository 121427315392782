import React, { useCallback, useEffect, useState, useRef } from "react";
import Select from "react-select";
import cx from "classnames";
import countries from "@/modules/countryCodes.json";
import territories from "@/modules/territories.json";
import useIntlTelInput from "@/modules/useIntlTelInput";
import { useAccordionContext } from "@/components/Accordion";
import { useField } from "./useCheckoutForm";

const Input = ({ type, validate, setValue, label, field, value, ...other }) => {
  const cursor = useRef(0);
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.selectionStart = cursor.current;
    inputRef.current.selectionEnd = cursor.current;
  }, [value]);

  const accordion = useAccordionContext();

  const inputProps = {
    ...other,
    className: "auth__input s16 x",
    name: field,
    placeholder: label,
    onChange: useCallback(
      (ev) => {
        cursor.current = ev.target.selectionStart;
        setValue(ev.target.value);
      },
      [setValue],
    ),
    onBlur: () => validate(),
    value: value || "",
    tabIndex: accordion?.isClosed ? -1 : 0,
  };

  return type === "textarea" ? (
    <textarea {...inputProps} ref={inputRef}>
      {label}
    </textarea>
  ) : (
    <input {...inputProps} ref={inputRef} />
  );
};

export const Field = (props) => {
  const { label, className, field, children, required: isRequired } = props;
  const { error, value, validate, setValue } = useField(field, isRequired);
  const inputProps = { value, validate, setValue };

  return (
    <label
      className={cx("checkout__input-group block x aic jcc mb05", className, {
        error,
      })}
    >
      <label className="block mb025">{label}</label>

      {children || <Input {...props} {...inputProps} />}

      {error && <code className="error">{error}</code>}
    </label>
  );
};

export const IntlPhoneInput = ({ field, actions, dispatch, ...rest }) => {
  const inputRef = useRef();
  const [itiRef, iti, getItiError] = useIntlTelInput();
  const { setValue, setError, unsetError, error } = useField(field);

  const accordion = useAccordionContext();
  const tabIndex = accordion?.isClosed ? -1 : 0;

  const hasMounted = useRef(false);

  useEffect(() => {
    setTimeout(
      () => {
        const flagDropdown = inputRef.current.parentNode.querySelector(
          ".iti__selected-flag",
        );
        if (flagDropdown) {
          flagDropdown.tabIndex = tabIndex;
        }
      },
      hasMounted.current ? 0 : 500,
    );
  }, [tabIndex]);

  /* Also listen to changes in the country field */
  const { value: country, error: countryError } = useField("country");
  useEffect(() => {
    if (iti && country && !countryError && !iti.getNumber()) {
      iti.setCountry(country);
    }
  }, [country, countryError, iti]);

  const handleChange = useCallback(() => {
    if (!iti) return;
    const val = iti.getNumber();
    setValue(val);

    if (val && error) setError(getItiError());
    else if (!error === false) unsetError();
  }, [iti, setValue, setError, unsetError, getItiError, error]);

  const handleBlur = useCallback(() => {
    if (!iti) return;
    const val = iti.getNumber();
    if (val) setError(getItiError());
  }, [iti, setError, getItiError]);

  const setRef = useCallback(
    (el) => {
      itiRef(el);
      inputRef.current = el;
    },
    [itiRef],
  );

  return (
    <input
      className="auth__input s16 x"
      type="text"
      name={field}
      tabIndex={tabIndex}
      onChange={handleChange}
      onBlur={handleBlur}
      ref={setRef}
      {...rest}
    />
  );
};

const findCountryByLabel = (label) =>
  label && countries.find((c) => c.label.toLowerCase() === label.toLowerCase());
const findCountryByCode = (code) =>
  code && countries.find((c) => c.value === code);

export const CountrySelect = () => {
  const { value: savedValue, setValue } = useField("country");
  const [renderAutoFillCatcher, setRenderAutoFillCatch] = useState(true);
  const hiddenInput = useRef(null);

  const accordion = useAccordionContext();

  /*
    The autofillcatcher needs to be removed and then remounted, whenever an
    autofill event happens
   */
  const catchAutofillUpdate = useCallback(
    (ev) => {
      const autoCountry = ev.target?.value?.toLowerCase();
      const country =
        findCountryByLabel(autoCountry) ||
        findCountryByCode(autoCountry?.toUpperCase());

      if (country) {
        setValue(country.value);
      }
      /* Remove the autofill catcher */
      setTimeout(() => setRenderAutoFillCatch(false), 0);
    },
    [setValue],
  );

  useEffect(() => {
    /* Remount the autofill catcher after a small timeout */
    if (!renderAutoFillCatcher) {
      setTimeout(() => setRenderAutoFillCatch(true), 64);
    }
  }, [renderAutoFillCatcher]);

  return (
    <>
      <Select
        options={countries}
        // defaultMenuIsOpen={true}
        onChange={(option) => setValue(option.value)}
        value={findCountryByCode(savedValue)}
        placeholder="Country*"
        className="country-select x"
        classNamePrefix="country-select"
        tabIndex={accordion?.isClosed ? -1 : 0}
      />

      {renderAutoFillCatcher && (
        <input
          name="country"
          id="hiddenCountryInput"
          ref={hiddenInput}
          className="hidden__country"
          tabIndex={-1}
          autoComplete="shipping country"
          onChange={catchAutofillUpdate}
        />
      )}
    </>
  );
};

export const TerritorySelect = () => {
  const { value: savedValue, setValue } = useField("territory");
  const accordion = useAccordionContext();
  return (
    <Select
      options={territories}
      // defaultMenuIsOpen={true}
      onChange={(option) => setValue(option.value)}
      value={savedValue && { label: savedValue, value: savedValue }}
      placeholder="Territory"
      className="country-select x"
      tabIndex={accordion?.isClosed ? -1 : 0}
      classNamePrefix="country-select"
    />
  );
};

export const SubmitButton = ({ onSubmit, disabled }) => (
  <button
    className="if jcb aic mt1 mb05 hover block asset__add-to-cart caps akz black"
    type="submit"
    disabled={disabled}
    tabIndex={0}
  >
    Request Invoice
    <div className="asset__add-to-cart__arrow ml05">
      <svg
        fill="currentColor"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 55 20"
      >
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          points="43.3,0 55,10 43.3,20 42.4,19.1 52.2,10.7 0,10.7 0,9.3 52.2,9.3   42.4,0.9 43.3,0 "
        ></polygon>
      </svg>
    </div>
  </button>
);
