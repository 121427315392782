import React from "react";

function useLoading() {
  const [isLoading, setState] = React.useState(false);
  const load = (aPromise) => {
    setState(true);
    return aPromise
      .then((...args) => {
        setState(false);
        return Promise.resolve(...args);
      })
      .catch((...args) => {
        setState(false);
        return Promise.reject(...args); // eslint-disable-line prefer-promise-reject-errors
      });
  };
  return [isLoading, load];
}

export default useLoading;
