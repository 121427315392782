import React, { useState, useEffect, useCallback } from "react";
import { Link, navigate } from "@/components/LinkWithSavedState";
import VimeoPlayer from "@vimeo/player";
import { IoMdClose } from "react-icons/io";
import cx from "classnames";
import MicroModal from "micromodal";

import { get, formatUsdShort } from "@/modules/utils";
import { identityHeadersPromise } from "@/providers/IdentityProvider";
import { useCart } from "@/providers/CartProvider";
import LoadingSpinner from "@/components/LoadingSpinner";
import Loading from "@/components/Loading";
import { EmblaCarousel } from "components/Carousel";

import {
  getStoredAssetInfo,
  makeSlide,
  makeRow,
  getMedia,
  makeVideoPreview,
  makeSlides,
} from "./utils";

const debug = window.debugAsset || false;

function Asset(props) {
  const [asset, setAsset] = useState(getStoredAssetInfo(props.assetSlug));
  const [video, setVideo] = useState();
  const [hideBackArrow, setHideBackArrow] = useState(false);

  const { addItems } = useCart();

  const addToCart = useCallback(
    (e) => {
      if (!asset || !asset._id) return;
      addItems([asset._id]);
    },
    [asset, addItems],
  );

  const {
    media,
    mediaShuttleFilePath,
    // film,
    // slug,
    title,
    price,
    info,
  } = asset || {};

  const [
    hasMedia,
    hasVideo,
    hoverIcon,
    preview,
    previewArray,
    vimeoId,
    vimeoSrc,
  ] = getMedia(media || {});

  const { formats, notes, restrictions, tags, trt, description, quantity } =
    info || {};

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);

  useEffect(() => {
    const storedAsset = getStoredAssetInfo(props.assetSlug);
    if (storedAsset) {
      debug &&
        console.log(
          `%cUSING STORED`,
          "background:#ffd040; color:#222;",
          storedAsset,
        );
      setAsset(storedAsset);
      return;
    }
    debug &&
      console.log(
        `%c NOT USING STORED`,
        "color: #221; background: #F40;padding: 2px 8px; font-weight: bold",
      );

    identityHeadersPromise()
      .then((headers) =>
        window.fetch(
          `/.netlify/functions/get-asset?film=${props.slug}&slug=${props.assetSlug}`,
          {
            method: "GET",
            credentials: "include",
            headers,
          },
        ),
      )
      .then((res) => res.json())
      .then((data) => {
        const _asset = get(data, "asset");
        if (_asset) setAsset(_asset);
        else navigate("/", { replace: true });
      })
      .catch((err) => console.log("asset fetch error:", err));
  }, [props.slug, props.assetSlug]);

  const openModal = useCallback(
    (e) => {
      e.preventDefault();

      if (!vimeoId) return;

      setHideBackArrow(true);

      try {
        MicroModal.show(vimeoId);
        const vimeoIframe = document.getElementById(`vimeo-${vimeoId}`);
        const player = new VimeoPlayer(vimeoIframe);
        setVideo(player);

        player.on("loaded", () => setShowLoadingSpinner(false));
        player.on("play", () => setShowLoadingSpinner(false));
      } catch (err) {
        console.error(err);
      }
    },
    [vimeoId],
  );

  const closeModal = useCallback(() => {
    setHideBackArrow(false);
    MicroModal.close(vimeoId);
    video.pause();
  }, [video, vimeoId]);

  let previewComponent = <></>;
  if (hasVideo) {
    previewComponent = makeVideoPreview({
      preview,
      title,
      vimeoId,
      openModal,
    });
  } else if (preview) {
    previewComponent = makeSlide({ preview, title });
  }
  const slides = makeSlides(previewArray, title);

  return (
    <div className="mxa container--m">
      {asset && (
        <Loading>
          <div className={cx("x film", { hidden: hideBackArrow })}>
            <Link
              to={`/films/${props.slug}`}
              back
              className="film__back if jcs aic"
            >
              <svg
                fill="currentColor"
                className="ml1 rotate"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 55 20"
              >
                <polygon
                  fillRule="evenodd"
                  clipRule="evenodd"
                  points="43.3,0 55,10 43.3,20 42.4,19.1 52.2,10.7 0,10.7 0,9.3 52.2,9.3   42.4,0.9 43.3,0 "
                />
              </svg>
              <h5 className="p0 my0 pl1 bcb rel z1">Back</h5>
            </Link>
          </div>

          <div className="x film__content py15 mt1">
            <div className="f jcb ais">
              {hasMedia && (
                <div className="rel x film__poster mxa hover">
                  {slides ? (
                    <EmblaCarousel>{slides}</EmblaCarousel>
                  ) : (
                    previewComponent
                  )}

                  <div className="abs z1 film__images-single-icon asset__play">
                    {hoverIcon}
                  </div>
                </div>
              )}

              {hasVideo && (
                <div
                  className="modal x f jcc aic"
                  id={`${vimeoId}`}
                  aria-hidden="true"
                >
                  <div className="x" tabIndex="-1" data-micromodal-close>
                    <div
                      className="x"
                      role="dialog"
                      aria-modal="true"
                      aria-labelledby={`${vimeoId}-title`}
                    >
                      <div
                        className="modal__video rel ma x"
                        id={`${vimeoId}-content`}
                      >
                        <button
                          onClick={closeModal}
                          className="abs f jcc aic modal__close z1 right top "
                          aria-label="Close modal"
                          data-micromodal-close
                        >
                          <IoMdClose />
                        </button>
                        <iframe
                          className="js-film__preview film__preview"
                          id={`vimeo-${vimeoId}`}
                          title={title}
                          allow="autoplay; fullscreen"
                          src={vimeoSrc}
                          allowFullScreen
                          allowtransparency="true"
                        />
                        {showLoadingSpinner && (
                          <div className="center abs">
                            <LoadingSpinner />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                className={cx("film__content-inner x px1", {
                  "mxa container--s": !preview,
                })}
              >
                <div className="container--xs">
                  <h3 className="m0 p0">{title}</h3>
                  <br />
                  <h4 className="m0 p0">{formatUsdShort(price)}</h4>
                  <br />
                  <table className="lh">
                    <tbody>
                      {makeRow("Description", description)}
                      {makeRow("Quanity", quantity)}
                      {makeRow("Format(s)", formats)}
                      {makeRow("TRT", trt)}
                      {makeRow("Tags", tags)}
                      {makeRow("Restrictions", restrictions)}
                      <tr>
                        {!preview && <td></td>}
                        {notes && (
                          <td colSpan={preview ? 2 : 1} className="py05">
                            <br />
                            <em>*{notes}</em>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>

                  <br />
                  <br />
                  {mediaShuttleFilePath && (
                    <div
                      className="if jcs aic my1 caps black asset__add-to-cart hover"
                      onClick={addToCart}
                    >
                      Add To Cart
                      <div className="asset__add-to-cart__arrow ml05">
                        <svg
                          fill="currentColor"
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 55 20"
                        >
                          <polygon
                            fillRule="evenodd"
                            clipRule="evenodd"
                            points="43.3,0 55,10 43.3,20 42.4,19.1 52.2,10.7 0,10.7 0,9.3 52.2,9.3   42.4,0.9 43.3,0 "
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Loading>
      )}
    </div>
  );
}

export default Asset;
