import React, { useCallback, useContext, useEffect, useState } from "react";

import {
  useIdentity,
  identityHeadersPromise,
} from "@/providers/IdentityProvider";
import { get } from "@/modules/utils";

const debug = window.debugOrders || false;

const OrdersContext = React.createContext();

/**
 * OrdersProvider does provides a getOrderHistory functoin, instead of providing
 * the orders array directly.
 *
 * This way, the fetch orders call is only performed when the orders are needed.
 */
const OrdersProvider = (props) => {
  const [orders, setOrders] = useState(null);
  const { isLoggedIn } = useIdentity();

  useEffect(() => {
    if (!isLoggedIn) {
      setOrders(null);
    }
  }, [isLoggedIn]);

  const getOrderHistory = useCallback(async () => {
    if (orders) return orders;

    debug &&
      console.log(`%corders being fetched`, "background:#ffd040; color:#222;");
    const headers = await identityHeadersPromise();
    const _orders = await window
      .fetch("/.netlify/functions/get-orders", {
        method: "GET",
        credentials: "include",
        headers,
      })
      .then((res) => res.json())
      .then((data) => data.orders)
      .catch((err) => {
        console.error("fetch order history error", err);
        return null;
      });

    if (!Array.isArray(_orders)) {
      setOrders(_orders);
    }

    debug && console.log(`%corders`, "background:blue; color:white;", _orders);
    return _orders;
  }, [orders]);

  const getOrder = useCallback(
    async (orderId) => {
      const _orders = orders || (await getOrderHistory());

      if (!_orders) return null;
      const _order = _orders.find((order) => orderId === get(order, "_id"));

      return _order || null;
    },
    [orders, getOrderHistory]
  );

  const updateOrder = useCallback(
    (orderId, newData) => {
      if (!orders) return false;

      let didUpdate = false;

      const newOrders = orders.map((order) => {
        if (order._id === orderId) {
          didUpdate = true;
          return {
            ...order,
            ...newData,
          };
        }
        return order;
      });
      setOrders(newOrders);
      debug &&
        console.log(
          `%cdidUpdate?`,
          "background:#bada55; color:#222;",
          didUpdate
        );
      return didUpdate;
    },
    [orders]
  );

  const context = {
    getOrderHistory,
    getOrder,
    updateOrder,
  };

  return (
    <OrdersContext.Provider value={context}>
      {props.children}
    </OrdersContext.Provider>
  );
};

export const useOrders = () => useContext(OrdersContext);

export default OrdersProvider;
