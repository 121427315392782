/* global intlTelInputUtils */

import { useState, useCallback, useEffect, useMemo } from "react";
import "intl-tel-input/build/css/intlTelInput.css";

// these are lazy loaded below
let intlTelInput;
let utilsLoaded = false;

const getItiErrorFromCode = (errorCode) => {
  const v = intlTelInputUtils.validationError;

  switch (errorCode) {
    case v.INVALID_COUNTRY_CODE:
      return "Invalid country code";
    case v.INVALID_LENGTH:
      return "Invalid length";
    case v.IS_POSSIBLE: {
      /* console.warn('IS_POSSIBLE'); */ return false;
    }
    case v.IS_POSSIBLE_LOCAL_ONLY:
      return "Must be international number";
    case v.TOO_LONG:
      return "Too long";
    case v.TOO_SHORT:
      return "Too short";
    default:
      return "Invalid Number";
  }
};

// eslint-disable-next-line consistent-return
const muteErrors =
  (context, func) =>
  (...args) => {
    try {
      const returnVal = func.apply(context, args);
      return returnVal;
    } catch (e) {}
  };

const useIntlTelInput = () => {
  const [inputElement, callbackRef] = useState();
  const [iti, setInputInstance] = useState();

  const [pkgReady, setPkgReady] = useState(!!intlTelInput);
  const [utilsReady, setUtilsReady] = useState(utilsLoaded);
  const ready = pkgReady && utilsReady;

  useEffect(() => {
    if (!utilsLoaded) {
      import("intl-tel-input/build/js/utils").then(() => {
        setUtilsReady(true);
        utilsLoaded = true;
      });
    }
    if (!intlTelInput) {
      import("intl-tel-input").then((module) => {
        intlTelInput = module.default;
        setPkgReady(true);
      });
    }
  }, []);

  useEffect(() => {
    if (!ready || !inputElement) return;

    const instance = intlTelInput(inputElement, {
      autoPlaceholder: "polite",
      separateDialCode: true,
      customContainer: "iti-container",
    });
    setInputInstance(instance);
  }, [ready, inputElement]);

  const safeIti = useMemo(
    () =>
      iti && {
        getNumber: muteErrors(iti, iti?.getNumber),
        setCountry: muteErrors(iti, iti?.setCountry),
        getValidationError: muteErrors(iti, iti?.getValidationError),
        isValidNumber: muteErrors(iti, iti?.isValidNumber),
      },
    [iti],
  );

  const getError = useCallback(() => {
    if (!iti || iti.isValidNumber()) return false;

    return getItiErrorFromCode(iti.getValidationError());
  }, [iti]);

  return [callbackRef, safeIti, getError];
};

export default useIntlTelInput;
