import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import { usePageState } from "@/providers/SavedPageState";

const RadioGroupNameContext = React.createContext();
const AccordionContext = React.createContext();

export const useAccordionContext = () => useContext(AccordionContext);

/**
 * If the props.label is not unique, pass a props.id instead
 */
const Accordion = (props) => {
  const parentAccordion = useContext(AccordionContext);
  const { groupName } = useContext(RadioGroupNameContext) || {};
  const [hasMounted, setHasMounted] = useState(false);
  const content = useRef({});
  const [maxHeight, setMaxHeight] = useState(0);
  const [isOpen, _setIsOpen] = useState(props.startOpen);
  const [pageStateOnPageLoad, updatePageState] = usePageState();

  const hiddenCheckbox = useRef();

  useEffect(() => {
    Object.entries(pageStateOnPageLoad).forEach(([key, val]) => {
      if ((props.id === key || props.label === key) && val) {
        _setIsOpen(val);
        hiddenCheckbox.current.checked = val;
      }
    });
  }, [pageStateOnPageLoad, props.id, props.label]);

  const setIsOpen = useCallback(
    (_isOpen) => {
      updatePageState(props.id || props.label, _isOpen);
      _setIsOpen(_isOpen);
    },
    [updatePageState, props.id, props.label],
  );

  const hasRetried = useRef(false);

  const measureHeight = useCallback(() => {
    const thisNode = content?.current;
    if (thisNode) {
      setMaxHeight(
        [
          thisNode,
          ...thisNode.querySelectorAll(".accordion-tab-content"),
        ].reduce((total, node) => (total += node.scrollHeight), 0),
      );
    } else if (!hasRetried.current) {
      // trigger another measure
      hasRetried.current = true;
      setTimeout(() => setHasMounted("retry"), 240);
    }
  }, []);

  const debounceTimeout = useRef();
  const debouncedMeasure = useCallback(() => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(measureHeight, 240);
  }, [measureHeight]);

  useEffect(() => {
    if (!hasMounted) setHasMounted(true);
    debouncedMeasure();
    window.addEventListener("resize", debouncedMeasure);
    return () => {
      window.removeEventListener("resize", debouncedMeasure);
    };
  }, [hasMounted, debouncedMeasure]);

  const handleKeyPress = useCallback(
    (e) => {
      if ([" ", "Enter"].includes(e.key)) {
        e.preventDefault();
        setIsOpen((wasOpen) => {
          hiddenCheckbox.current.checked = !wasOpen;
          return !wasOpen;
        });
      }
    },
    [setIsOpen],
  );

  const contextValue = useMemo(
    () => ({
      isClosed: !isOpen,
    }),
    [isOpen],
  );

  return (
    <div className={props.className}>
      <input
        type={groupName ? "radio" : "checkbox"}
        className="accordion-input"
        ref={hiddenCheckbox}
        tabIndex={-1}
        id={props.id || props.label}
        name={groupName}
        onChange={({ target }) => setIsOpen(target.checked)}
        defaultChecked={props.startOpen}
      />

      <label
        tabIndex={parentAccordion?.isClosed ? -1 : 0}
        onKeyPress={handleKeyPress}
        htmlFor={props.id || props.label}
        className={cx("accordion-tab-label semi akz-e py025", props.labelClass)}
      >
        {props.label}
      </label>

      <div
        className="accordion-tab-content"
        style={{ maxHeight: isOpen ? maxHeight : 0 }}
        ref={content}
      >
        <AccordionContext.Provider value={contextValue}>
          <RadioGroupNameContext.Provider value={null}>
            {props.children}
          </RadioGroupNameContext.Provider>
        </AccordionContext.Provider>
      </div>
    </div>
  );
};

/**
 * If you specify a groupName, the child accordions will be radio buttons,
 * i.e. only one of them will be allowed open at a time.
 */
export const AccordionGroup = ({ groupName, children }) => (
  <RadioGroupNameContext.Provider value={{ groupName }}>
    {children}
  </RadioGroupNameContext.Provider>
);

export default Accordion;
