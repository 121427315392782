import React from "react";
import { useIdentity } from "../providers/IdentityProvider";

import userGuide from "../assets/A24 International Servicing Site User Guide_7.13.2022.pdf";

function UserGuide({ className }) {
  const { isLoggedIn } = useIdentity();

  return isLoggedIn ? (
    <a className={className} href={userGuide} download>
      User Guide
    </a>
  ) : (
    <></>
  );
}

export default UserGuide;
