import { identityHeadersPromise } from "providers/IdentityProvider";

export const regenerateLinkCallback = (orderId) =>
  identityHeadersPromise()
    .then((headers) =>
      window.fetch(
        `/.netlify/functions/regenerate-one-time-link?orderId=${orderId}`,
        {
          method: "GET",
          credentials: "include",
          headers,
        },
      ),
    )
    .then((res) => res.json())
    .then((data) => {
      console.log(
        `%cdata`,
        "color: #221; background: #F40;padding: 2px 8px; font-weight: bold",
        data,
      );
    });
