import React from "react";
import { TiArrowRightThick } from "react-icons/ti";

import useLoading from "../../modules/useLoading";
import LoadingSpinner from "../../components/LoadingSpinner";
import { identityHeadersPromise } from "../../providers/IdentityProvider";

async function postInvite(email) {
  const headers = await identityHeadersPromise();

  return window
    .fetch("/.netlify/functions/create-user", {
      method: "POST",
      credentials: "include",
      headers,
      body: JSON.stringify({ email }),
    })
    .then((res) => res.json());
}

function CreateUser() {
  const formRef = React.useRef();
  const [msg, setMsg] = React.useState();
  const [cmsUserLink, setCmsUserLink] = React.useState();
  const [isLoading, load] = useLoading();

  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      const email = event.target.email.value;
      setMsg(null);
      setCmsUserLink(null);
      load(
        postInvite(email)
          .then((res) => {
            console.log("%cresponse", "background:#ffd040; color:#222;", res);
            setMsg(`${res.message || res.msg} ${res.error || ""}`);
            setCmsUserLink(res.cmsUserLink);
          })
          .catch(
            (err) => console.error(err) || setMsg(`Error: ${err.message}`),
          ),
      );
    },
    [load],
  );

  return (
    <div className="ac x container--xs mra auth__none f jcc aic">
      <div className="x">
        <div>
          <form ref={formRef} onSubmit={handleSubmit}>
            <div>
              <h3 className="mb05">Enter the new user's email</h3>
              <span className="akz-e small mb025 inline-block">
                Netlify will send an invitation +
              </span>
              <br />
              <span className="akz-e small mb1 inline-block">
                A user will be created in the Sanity CMS
              </span>
              <label>
                <input
                  className="auth__input x p1 mb1"
                  type="email"
                  name="email"
                  placeholder="Email"
                />
              </label>
            </div>

            <div>
              <input
                className="button m05 akz caps mr1"
                type="submit"
                value="Send Invite"
                disabled={isLoading}
              />
              <br />

              <div style={{ minHeight: "175px" }}>
                {isLoading && <LoadingSpinner />}

                {msg && (
                  <>
                    <br />
                    <code className="ws-pl">{msg}</code>
                  </>
                )}

                {cmsUserLink && (
                  <div className="f aic jcc mt1 admin__user-link">
                    <a href={cmsUserLink} className="f aic">
                      <TiArrowRightThick
                        color="#41f541"
                        style={{
                          fontSize: "2em",
                          transform: "translate(-5px, -1px)",
                        }}
                      />
                      <code>Go to user in CMS</code>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateUser;
