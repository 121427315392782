import React, { useEffect, useState } from "react";
import { Link } from "@/components/LinkWithSavedState";
import cx from "classnames";

const defaultText = (
  <span>
    Assets will be available immediately.
    <br />
    Payment is due upon invoice receipt.
    <br />
  </span>
);

const statusMap = {
  invoice: {
    title: "Creating Invoice",
  },
  order: {
    title: "Creating Order",
  },
  assets: {
    title: "Preparing Assets",
  },
  complete: {
    title: "Assets Ready",
    isLoading: false,
    text: (
      <span>
        Assets are available in your order history page.
        <br />
        The download links will expire after one week.
        <br />
      </span>
    ),
  },
  problem: {
    title: "Order could not be created",
    isLoading: false,
    text: (
      <span>
        There was a problem creating your order.
        <br />
        Please contact{" "}
        <a
          href="mailto:support@a24films.com?subject=Problem creating order"
          className="underline"
        >
          A24 support
        </a>{" "}
        for assistance.
        <br />
      </span>
    ),
  },
};

export const OrderProcessingStatus = ({ status, newOrderSlug }) => {
  const {
    title,
    isLoading = true,
    text = defaultText,
  } = statusMap[status] || {};

  const [fadeInTitle, setTitleFade] = useState(false);
  const [fadeInText, setTextFade] = useState(false);

  useEffect(() => {
    setTitleFade(false);
    setTimeout(() => setTitleFade(true), 20);
  }, [title]);

  useEffect(() => {
    setTextFade(false);
    setTimeout(() => setTextFade(true), 64);
  }, [text]);

  return (
    <div className="abs fill x f z2 jcc aic order-processing">
      <div className="p15 bcb border ac">
        <h1
          className={cx({
            "loading-dots pr05": isLoading,
            "fade-in": fadeInTitle,
            hidden: !fadeInTitle,
          })}
        >
          {title}
        </h1>

        <div
          className={cx({
            "fade-in": fadeInText,
            hidden: !fadeInText,
          })}
        >
          {text}
        </div>

        <br />
        {newOrderSlug && (
          <Link
            to={`/orders/${newOrderSlug}`}
            className="fade-in if order-success-link"
          >
            Go to order page
            <div className="asset__add-to-cart__arrow order-success-link__arrow ml05">
              <svg
                fill="currentColor"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 55 20"
              >
                <polygon
                  fillRule="evenodd"
                  clipRule="evenodd"
                  points="43.3,0 55,10 43.3,20 42.4,19.1 52.2,10.7 0,10.7 0,9.3 52.2,9.3   42.4,0.9 43.3,0 "
                />
              </svg>
            </div>
          </Link>
        )}

        {isLoading && (
          <span>
            Do not reload or close this tab.
            <br />
          </span>
        )}
      </div>
    </div>
  );
};
