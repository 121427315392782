import React from "react";
import { Link } from "@/components/LinkWithSavedState";
import cx from "classnames";
import { MdOpenInNew, MdBrokenImage } from "react-icons/md";

import Image from "@/components/Image";

const LIST = "list";
const SQUARE = "square";
const LETTERBOX = "letterbox";

const AssetThumb = ({
  filmSlug,
  film,
  price,
  slug,
  title,
  media,
  displayType = LIST,
}) => {
  const { thumbnail, preview } = media || {};
  const image = thumbnail || preview;

  // if (displayType === LIST) or default
  if (displayType !== SQUARE && displayType !== LETTERBOX) {
    return (
      <div className="pl1 py075 films__row x f jcb aic rel">
        <Link
          to={`/films/${filmSlug}/${slug.current}`}
          className="abs z1 x y top left"
        />
        <div className="ml05 f jcb aic">
          {title}
          <svg
            fill="currentColor"
            className="ml1"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 55 20"
          >
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              points="33.3,0 45,10 33.3,20 32.4,19.1 42.2,10.7 0,10.7 0,9.3 42.2,9.3   32.4,0.9 33.3,0 "
            />
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div className="asset__preview film__images-single mr1 mb1 rel oh">
      <Link
        to={`/films/${filmSlug}/${slug.current}`}
        className="abs z2 x y top left"
      />
      <div className="rel">
        <div className="abs z1 film__images-single-icon s22">
          <MdOpenInNew />
        </div>

        <div
          className={cx("f jcc aic s22 centerX", {
            "asset__thumb-square": displayType === SQUARE,
            "asset__thumb-letterbox": displayType === LETTERBOX,
          })}
        >
          {image ? (
            <Image className="x object-fit" source={`${image}?w=166`} alt="" />
          ) : (
            <MdBrokenImage
              className="image__block is-visible"
              style={{ transform: "scale(3)" }}
            />
          )}
        </div>
      </div>

      <h5 className="m05 p0 ac">{title}</h5>
    </div>
  );
};

export default AssetThumb;
