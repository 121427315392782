import React from "react";
import { IoIosCloudDownload, IoIosPlay } from "react-icons/io";
import { MdBrokenImage } from "react-icons/md";
import Image from "@/components/Image";
import BlockContent from "@sanity/block-content-to-react";

// find the asset in the stored __assets JSON if it exists
export function getStoredAssetInfo(assetSlug) {
  try {
    const assets = JSON.parse(window.__assets);
    return assets.find((asset) => asset.slug.current === assetSlug) || null;
  } catch (e) {
    return null;
  }
}

export const makeSlide = ({ preview, title, allowDownload = true }) =>
  allowDownload ? (
    <a
      className="rel mb05 height100 film__video-preview block"
      href={`${preview}?dl=${title}`}
    >
      <Image className="x contain" source={`${preview}?w=400`} alt={title} />
    </a>
  ) : (
    <Image className="x contain" source={`${preview}?w=400`} alt={title} />
  );

export const makeRow = (attr, val) => {
  if (!val) return null;

  if (Array.isArray(val)) {
    val = <BlockContent blocks={val} />;
  }

  return (
    <tr>
      <td className="f ais py025">{attr}</td>
      <td className="pl075 py025 ws-pl">{val}</td>
    </tr>
  );
};

export const makeVideoPreview = ({ preview, title, vimeoId, openModal }) => (
  <div
    className="rel mb05 film__video-preview link"
    data-micromodal-trigger={`${vimeoId}`}
    onClick={openModal}
  >
    {preview ? (
      makeSlide({ preview, title, allowDownload: false })
    ) : (
      <MdBrokenImage
        className="centerX mt1"
        style={{ height: "100px", width: "100px" }}
      />
    )}
  </div>
);

export const getMedia = ({ previewArray, vimeoId }) => {
  const preview = Array.isArray(previewArray) ? previewArray[0] : undefined;
  const hasMedia = preview || vimeoId;
  const hasVideo = vimeoId;
  const hasPreviewOnly = !vimeoId && preview;

  let vimeoSrc = undefined;
  let privacyId = undefined;
  if (typeof vimeoId === "string") {
    // If the vimeoId is like: 12345/6789, then the 6789 is the privacyId
    [vimeoId, privacyId] = vimeoId.split("/");

    vimeoSrc = `https://player.vimeo.com/video/${vimeoId}`;
    if (privacyId) {
      vimeoSrc += `?h=${privacyId}&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`;
    } else {
      vimeoSrc += `?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`;
    }
  }

  const hoverIcon = hasPreviewOnly ? <IoIosCloudDownload /> : <IoIosPlay />;

  return [
    hasMedia,
    hasVideo,
    hoverIcon,
    preview,
    previewArray,
    vimeoId,
    vimeoSrc,
  ];
};

export const makeSlides = (previewArray, title) => {
  if (Array.isArray(previewArray) && previewArray.length > 1) {
    return previewArray.map((url) => makeSlide({ preview: url, title }));
  }
  return null;
};
