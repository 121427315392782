import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { globalHistory } from '@reach/router';
import { apiCall } from '@/api/apiCall';
import { useIdentity } from './IdentityProvider';

const CartContext = React.createContext();

const debug = window.debugCart || false;

const formatDataFromServer = ({
  items = [],
  total = 0,
  discount = null,
  newOrder,
}) => ({
  items,
  total,
  discount,
});

const CartProvider = (props) => {
  const { isLoggedIn } = useIdentity();

  const [cart, _setCart] = useState(formatDataFromServer({}));
  const setCart = useCallback(
    (data = {}) => _setCart(formatDataFromServer(data.newOrder ? {} : data)),
    [],
  );

  const [isLocked, setIsLocked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);

  /* If multiple items are being added at the same time, show multiple loading indicators */
  const [addingCount, setAddingCount] = useState(0);
  /* Show loading indicators directly on items being removed */
  const [itemsBeingRemoved, setItemsBeingRemoved] = useState({});

  const fetchCart = useCallback(async () => {
    try {
      const [data] = await apiCall('get-cart');
      debug &&
        console.log(
          '%ccartData fetched',
          'background:blue; color:white;',
          data,
        );

      setCart(data);
      if (data?.newOrder) {
        const [newOrderData] = await apiCall('checkout-finish');
        if (newOrderData?.success) {
          window.location.reload();
        }
      }
    } catch (e) {}
  }, [setCart]);

  useEffect(() => {
    /* If the user logs out, close the cart and checkout screen */
    if (isLoggedIn) {
      fetchCart();
    } else {
      setIsOpen(false);
      setIsCheckoutOpen(false);
      setCart();
    }
  }, [isLoggedIn, fetchCart, setCart]);

  useEffect(() => {
    // on location change, close the cart
    globalHistory.listen(() => {
      setIsOpen(false);
      setIsCheckoutOpen(false);
    });
  }, []);

  debug && (window.cart = cart);

  const addItems = useCallback(
    async (itemIds) => {
      debug &&
        console.log(
          `%caddToCart ${itemIds.length} itemIds:`,
          'background:#ffd040; color:#222;',
          itemIds,
        );

      const itemsCount = itemIds.length;
      setIsOpen(true);
      setAddingCount((prev) => prev + itemsCount);

      const [data, status] = await apiCall(
        'add-to-cart',
        { ids: JSON.stringify(itemIds) },
        { method: 'POST' },
      );

      if (status === 200) {
        setCart(data);
        debug &&
          console.log(
            '%ccartData fetched (after item added)',
            'background:blue; color:white;',
            data,
          );
      } else if (status === 409) {
        debug && console.warn('already added');
      } else if (status === 400) {
        const missingFieldsMessage = data?.msg;
        debug &&
          console.warn(
            `Could not add-to-cart because: ${missingFieldsMessage}`,
          );
      }

      setAddingCount((prev) => prev - itemsCount);
    },
    [setCart],
  );

  const removeItem = useCallback(
    async (itemId) => {
      debug &&
        console.log(
          '%cremoveItem itemId:',
          'background:#ffd040; color:#222;',
          itemId,
        );

      setItemsBeingRemoved((prev) => ({ ...prev, [itemId]: true }));

      const [data, status] = await apiCall(
        'remove-from-cart',
        { _id: itemId },
        { method: 'DELETE' },
      );

      if (status === 200) {
        debug &&
          console.log(
            '%ccartData fetched (after item removed)',
            'background:blue; color:white;',
            data,
          );
        setCart(data);
      } else if (status === 409) {
        debug && console.warn('already deleted');
      } else if (status === 400) {
        const missingFieldsMessage = data?.msg;
        debug &&
          console.warn(
            `Could not remove-from-cart because: ${missingFieldsMessage}`,
          );
      }

      setItemsBeingRemoved((prev) => ({ ...prev, [itemId]: false }));
    },
    [setCart],
  );

  const removeClientId = useCallback(async () => {
    setItemsBeingRemoved((prev) => ({ ...prev, discount: true }));

    const [data, status] = apiCall(
      'remove-authorization-id',
      { _id: cart.discount.id },
      { method: 'DELETE' },
    );

    if (status === 200) {
      const { items = [] } = cart;

      setCart({
        items,
        total: items.reduce((t, { price }) => t + price, 0),
      });
    } else if (data.msg) {
      debug &&
        console.warn(`%cdata.msg`, 'background:pink; color:black;', data.msg);
    }

    setItemsBeingRemoved((prev) => ({ ...prev, discount: false }));
  }, [cart, setCart]);

  const cartContextValue = useMemo(
    () => ({
      ...cart,
      isOpen,
      isCheckoutOpen,
      isLocked,
      setIsLocked,
      addingCount,
      setCart,
      removeClientId,
      open: () => setIsOpen(true),
      close: () => {
        setIsOpen(false);
        setIsCheckoutOpen(false);
      },
      closeIfCheckoutIsNotOpen: () => !isCheckoutOpen && setIsOpen(false),
      openCheckout: () => setIsCheckoutOpen(true),
      closeCheckout: () => setIsCheckoutOpen(false),
      addItems,
      isItemLoading: (itemId) => !!itemsBeingRemoved[itemId],
      removeItem,
    }),
    [
      cart,
      setCart,
      isOpen,
      setIsOpen,
      isCheckoutOpen,
      setIsCheckoutOpen,
      isLocked,
      setIsLocked,
      addItems,
      removeItem,
      removeClientId,
      itemsBeingRemoved,
      addingCount,
    ],
  );

  return (
    <CartContext.Provider value={cartContextValue}>
      {props.children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);

export default CartProvider;
