import spacetime from "spacetime";
import informal from "spacetime-informal";

export function getNiceDate(date) {
  const { tz } = spacetime();
  const s = spacetime(date).goto(tz);
  const display = informal.display(tz);

  // let s = spacetime.now(display.iana)
  let abbrev = "";
  try {
    abbrev = s.isDST() ? display.daylight.abbrev : display.standard.abbrev;
  } catch (e) {
    console.error("could not get abbrev");
  }

  return {
    short: s.unixFmt("M/d/yy"),
    long: `${s.format("{month-short} {date-pad}, {year} {time}")} ${abbrev}`,
  };
}
