import React, { useCallback } from "react";
import { navigate as _navigate } from "@reach/router";

export const Link = ({ to, back, className, children }) => {
  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      if (back && Boolean(window.history.state)) {
        _navigate(-1);
      } else {
        _navigate(to);
      }
    },
    [to, back],
  );

  return (
    <a onClick={onClick} href={to} className={className}>
      {children}
    </a>
  );
};

export const navigate = _navigate;
