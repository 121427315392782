import React, { useEffect, useRef } from "react";
import { TiArrowRightThick } from "react-icons/ti";

import useLoading from "../../modules/useLoading";
import LoadingSpinner from "../../components/LoadingSpinner";
import { regenerateLinkCallback } from "modules/regenerateLinkCallback";

function RegenerateLink() {
  const formRef = React.useRef();
  const [msg, setMsg] = React.useState();
  const [cmsLink, setCmsLink] = React.useState();
  const [isLoading, load] = useLoading();
  const inputRef = useRef();

  useEffect(() => {
    const orderId = new URLSearchParams(window.location.search).get(
      "regenerateOrder",
    );
    inputRef.current.value = orderId;
  }, []);

  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      const orderId = event.target.orderId.value;
      setMsg(null);
      setCmsLink(null);
      load(regenerateLinkCallback(orderId));
    },
    [load],
  );

  return (
    <div className="ac x container--xs mra auth__none f jcc aic">
      <div className="x">
        <div>
          <form ref={formRef} onSubmit={handleSubmit}>
            <div>
              <h3 className="mb05">Enter an order id</h3>
              <span className="akz-e small mb025 inline-block">
                This will regenerate a download link
              </span>
              <br />
              <span className="akz-e small mb1 inline-block">
                and attach it to the Order's Details
              </span>
              <label>
                <input
                  ref={inputRef}
                  className="auth__input x p1 mb1"
                  type="text"
                  name="orderId"
                  placeholder="Order Id"
                />
              </label>
            </div>

            <div>
              <input
                className="button m05 akz caps mr1"
                type="submit"
                value="Generate Link"
                disabled={isLoading}
              />
              <br />

              <div style={{ minHeight: "175px" }}>
                {isLoading && <LoadingSpinner />}

                {msg && (
                  <>
                    <br />
                    <code className="ws-pl">{msg}</code>
                  </>
                )}

                {cmsLink && (
                  <div className="f aic jcc mt1 admin__user-link">
                    <a href={cmsLink} className="f aic">
                      <TiArrowRightThick
                        color="#41f541"
                        style={{
                          fontSize: "2em",
                          transform: "translate(-5px, -1px)",
                        }}
                      />
                      <code>Go to order in CMS</code>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegenerateLink;
