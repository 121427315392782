import React, { useState, useEffect } from "react";
import cx from "classnames";

const debug = false;

const Loading = ({ ready, children }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (typeof ready === "undefined" || ready) {
      setTimeout(() => setLoaded(true), 300);
    }
  }, [ready]);

  debug &&
    console.log(`%cloaded? ${loaded}`, "background:#bada55; color:#222;");

  return <div className={cx("film__single", { loaded })}>{children}</div>;
};

export default Loading;
