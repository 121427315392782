import React from "react";

import { Link } from "@/components/LinkWithSavedState";

import UserGuide from "./UserGuide";

export const Footer = (props) => (
  <div className={`container--l mxa ac py1 ${props.className || ""}`}>
    <Link to="/terms" className="pb1 px05">
      Terms and Conditions
    </Link>
    <UserGuide className="pb1 px05 ml025" />
  </div>
);
