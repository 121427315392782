import React, {
  useMemo,
  useState,
  useEffect,
  useReducer,
  useContext,
  useCallback,
} from "react";

import formReducer, { reset, getErrorMessage } from "./formReducer";

/**
 * FormContext - the <form> element provides a context.
 *               Any child of this context can access the form via hook 🎣
 */
const FormContext = React.createContext();

export const withCheckoutFormContext = (Component) => (props) => {
  const [form, dispatch] = useReducer(formReducer, {}, reset);

  const [requiredFields, _setRequired] = useState([]);
  const setRequiredField = useCallback((fieldName) => {
    _setRequired((fields) =>
      fields.includes(fieldName) ? fields : fields.concat(fieldName),
    );
  }, []);

  const validateForm = useCallback(() => {
    // force validation of required fields, generating their error messages
    requiredFields.forEach((field) =>
      dispatch({ type: "validate", payload: field }),
    );

    const isValid = (field) => !getErrorMessage(field, form?.values[field]);
    return requiredFields.every(isValid);
  }, [requiredFields, form]);

  const init = useCallback(
    (obj) => dispatch({ type: "init", payload: obj }),
    [],
  );
  const _reset = useCallback(
    (obj = {}) => dispatch({ type: "reset", payload: obj }),
    [],
  );
  const setValue = useCallback(
    (field, val) => dispatch({ type: "setValue", payload: { [field]: val } }),
    [],
  );

  return (
    <FormContext.Provider value={[form, dispatch, setRequiredField]}>
      <Component
        {...props}
        form={form}
        validate={validateForm}
        setValue={setValue}
        init={init}
        reset={_reset}
      />
    </FormContext.Provider>
  );
};

/**
 * useCheckoutForm
 *   returns the values from the formReducer. The `form` value is stateful, so
 *   it will cause re-renders.
 */
export const useCheckoutForm = () =>
  useContext(FormContext) ||
  console.error(
    "useCheckoutForm must be used in the context of the CheckoutForm's FormContext.Provider",
  );

/**
 * useField
 *   returns stateful value and error, with a static set of actions.
 */
export const useField = (field, isRequired) => {
  const [form, dispatch, setRequiredField] = useCheckoutForm();
  const [error, setError] = useState();
  const [value, setValue] = useState();

  useEffect(() => {
    if (form.errors[field] !== error) {
      setError(form.errors[field]);
    }
    if (form.values[field] !== value) {
      setValue(form.values[field]);
    }
  }, [form, error, value, field]);

  useEffect(() => {
    if (isRequired) {
      setRequiredField(field);
    }
  }, [setRequiredField, field, isRequired]);

  return useMemo(
    () => ({
      setError: (msg) =>
        msg && dispatch({ type: "setError", payload: { [field]: msg } }),
      setValue: (val) =>
        dispatch({ type: "setValue", payload: { [field]: val } }),
      unsetError: () => dispatch({ type: "unsetError", payload: field }),
      validate: () => dispatch({ type: "validate", payload: field }),
      value,
      error,
    }),
    [dispatch, field, value, error],
  );
};
